import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import accountAPI from "../../API/account";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  iconACB,
  iconTechcombank,
  iconVietinBank,
  iconBIDV,
  iconVietcomBank,
  iconMomo,
  abbank,
  acb,
  agribank,
  noiconbank,
  bidv,
  cimb,
  coopbank,
  dongabank,
  eximbank,
  gbbank,
  hdbank,
  hongleongbank,
  hsbc,
  ibk,
  ivb,
  kienlongbank,
  lienvietpostbank,
  mb,
  msb,
  namabank,
  ncb,
  nhb,
  ocb,
  oceanbank,
  pgbank,
  publicbank,
  pvcombank,
  sacombank,
  saigonbank,
  scb,
  seabank,
  shb,
  shinhanbank,
  techcombank,
  tpbank,
  ubank,
  uob,
  vib,
  vietabank,
  vietbank,
  vietcapitalbank,
  vietcombank,
  vietinbank,
  vpbank,
  vrb,
  wooribank,
  yolo,
} from "../../img/importImg";
import Select from "react-select";

const bankIcon = {
  ACB: iconACB,
  TECHCOMBANK: iconTechcombank,
  VIETINBANK: iconVietinBank,
  BIDV: iconBIDV,
  VIETCOMBANK: iconVietcomBank,
  momo: iconMomo,
  abbank: abbank,
  acb: acb,
  agribank: agribank,
  noiconbank: noiconbank,
  bidv: bidv,
  cimb: cimb,
  coopbank: coopbank,
  dongabank: dongabank,
  eximbank: eximbank,
  gbbank: gbbank,
  hdbank: hdbank,
  hongleongbank: hongleongbank,
  hsbc: hsbc,
  ibk: ibk,
  ivb: ivb,
  kienlongbank: kienlongbank,
  lienvietpostbank: lienvietpostbank,
  mb: mb,
  msb: msb,
  namabank: namabank,
  ncb: ncb,
  nhb: nhb,
  ocb: ocb,
  oceanbank: oceanbank,
  pgbank: pgbank,
  publicbank: publicbank,
  pvcombank: pvcombank,
  sacombank: sacombank,
  saigonbank: saigonbank,
  scb: scb,
  seabank: seabank,
  shb: shb,
  shinhanbank: shinhanbank,
  techcombank: techcombank,
  tpbank: tpbank,
  ubank: ubank,
  uob: uob,
  vib: vib,
  vietabank: vietabank,
  vietbank: vietbank,
  vietcapitalbank: vietcapitalbank,
  vietcombank: vietcombank,
  vietinbank: vietinbank,
  vpbank: vpbank,
  vrb: vrb,
  wooribank: wooribank,
  yolo: yolo,
};

function AddBanking() {
  const [user, setUser] = useState({ username: "", fullname: "" });
  const [isAddBanking, setIsAddBanking] = useState(false);
  const [nameBank, setNameBank] = useState();
  const [stk, setStk] = useState();
  const [nameuser, setNameUser] = useState();
  const [listBankByAdmin, setListBankByAdmin] = useState();

  // console.log(user);

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const listBank = await withdrawAPI.getListBank();
        const result = await accountAPI.getUserInfo();

        if (listBank?.status === true) {
          const listBankNew = listBank.data.map((item) => {
            return { value: item.title, label: item.title };
          });

          setListBankByAdmin(listBankNew);
        }

        if (result.status === false) {
          return toast.error(result.msg, { theme: "light" });
        }
        // console.log(result);
        if (result.bank_name) {
          const icon = bankIcon[result?.bank_name.toUpperCase()];
          result.icon = icon;
          setUser(result);
        } else setUser(result);

        if (result.bank_user) {
          setIsAddBanking(true);
        }
        // Không có status
        // if (result.status === true) {
        // }
      };
      fetchGetUserInfo();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);

  const handleAddBanking = async () => {
    try {
      if (!nameuser || !stk || !nameBank) {
        return toast.error("Không được để trống!", { theme: "light" });
      }

      const data = {
        nameuser: nameuser,
        stk: stk,
        nameBank: nameBank,
      };

      const result = await withdrawAPI.postAddBanking(data);
      //   console.log(result);
      if (result.status === true) {
        toast.success(
          result.msg || "Cập nhật tài khoản ngân hàng thành công!",
          { theme: "light" }
        );

        window.location.href = "/my/withdraw";
      } else {
        toast.error(result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  };

  const handleChangeNameBank = (value) => {
    setNameBank(value.value);
  };

  return (
    <>
      {!isAddBanking ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="w-25 py-3">
            <span>Username: </span>
            <Input
              className="mt-2"
              style={{ height: "35px", fontSize: "14px" }}
              disabled={`${user?.username ? true : false}`}
              defaultValue={`${user?.username}`}
            ></Input>
          </div>

          <div className="w-25 py-3">
            <span>
              <span style={{ color: "red" }}>*</span> Họ và tên chủ tài khoản:{" "}
            </span>
            <Input
              className="mt-2"
              style={{ height: "35px", fontSize: "14px" }}
              placeholder="Nhập họ và tên chủ tài khoản"
              onChange={(e) => setNameUser(e.target.value)}
            ></Input>
          </div>

          <div className="w-25 py-3">
            <span>
              <span style={{ color: "red" }}>*</span> Tên ngân hàng:{" "}
            </span>
            {listBankByAdmin && (
              <Select
                options={listBankByAdmin}
                onChange={handleChangeNameBank}
              />
            )}
          </div>

          <div className="w-25 py-3">
            <span>
              <span style={{ color: "red" }}>*</span> Số tài khoản:{" "}
            </span>
            <Input
              className="mt-2"
              style={{ height: "35px", fontSize: "14px" }}
              placeholder="Vui lòng nhập số tài khoản"
              onChange={(e) => setStk(e.target.value)}
            ></Input>
          </div>

          <Button
            color="primary"
            style={{ height: "35px", fontSize: "14px" }}
            className="mt-4"
            onClick={handleAddBanking}
          >
            Liên kết ngân hàng ngay
          </Button>
        </div>
      ) : (
        <div className="bg-light bg-gradient w-25 d-flex py-4 px-4">
          <img src={user?.icon} alt="" className="w-25 h-25" />
          <div className="d-flex flex-column pl-4">
            <span>{user?.bank_name.toUpperCase()}</span>
            <span className="pt-2">{user?.bank_user}</span>
            <span className="pt-2">Số tài khoản: {user?.bank_number}</span>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default AddBanking;
