import React, { useState, useEffect } from "react";
import rechargeAPI from "../../API/rechargeAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import gameAPI from "../../API/gameAPI";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function OttherHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [betHistory, setBetHistory] = useState([]);

  useEffect(() => {
    try {
      const fetchGetBetHistory = async () => {
        setIsLoading(true);
        const result = await gameAPI.getRefundLogs();

        if (result.status === true) {
          setBetHistory(result.data);
        } else {
          toast.error(result.msg, { theme: "light" });
        }

        setIsLoading(false);
      };

      fetchGetBetHistory();
    } catch (error) {
      return toast.error(error, { theme: "light" });
    }
  }, []);

  return (
    <div>
      {betHistory.length > 0 ? (
        <table className="table table-bordered rounded table-hover">
          <thead className="text-center" style={{ backgroundColor: "#e8f0fc" }}>
            <th>Thời gian</th>
            <th>Sảnh</th>
            <th>Tiền cược</th>
            <th>Hoàn trả</th>
          </thead>
          <tbody>
            {betHistory.map((item, i) => {
              return (
                <tr key={i} className="text-center">
                  <td>{item.create_at}</td>
                  <td>{item.provider_code}</td>
                  <td>{VND.format(item.total_bet)}</td>
                  <td>{VND.format(item.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <>
          <table className="table table-bordered rounded">
            <thead
              className="text-center"
              style={{ backgroundColor: "#e8f0fc" }}
            >
              <th>Thời gian</th>
              <th>Sảnh</th>
              <th>Tiền cược</th>
              <th>Hoàn trả</th>
            </thead>
          </table>
          <span className="d-flex align-items-center justify-content-center py-2">
            Không có dữ liệu
          </span>
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default OttherHistory;
