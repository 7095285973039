import axiosClient from "./axiosClient";

const pageAPI = {
  getLogo: () => {
    const url = `/api/user/getLogo`;
    return axiosClient.get(url);
  },

  getBanner: () => {
    const url = `/api/user/getBannerPC`;
    return axiosClient.get(url);
  },

  getPromotion: () => {
    const url = `/api/user/getPromotion`;
    return axiosClient.get(url);
  },

  //   putChangePWTrans: (data) => {
  //     const url = `/api/user/edit-password-transaction`;
  //     return axiosClient.put(url, data);
  //   },
};

export default pageAPI;
