import axiosClient from './axiosClient';

const accountAPI = {
    postRegister: (data) => {
        const url = `/api/user/register`;
        return axiosClient.post(url, data);
    },

    postLogin: (data) => {
        const url = `/api/user/login`;
        return axiosClient.post(url, data);
    },

    getUserInfo: () => {
        const url = `/api/user/userInfo`;
        return axiosClient.get(url);
    },

    withdraw: () => {
        const url = `/api/user/withdraw/bac`;
        return axiosClient.get(url);
    },

    putChangePW: (data) => {
        const url = `/api/user/change_password`;
        return axiosClient.put(url, data);
    },

    putChangePWTrans: (data) => {
        const url = `/api/user/edit-password-transaction`;
        return axiosClient.put(url, data);
    },
};

export default accountAPI;
