import axiosClient from "./axiosClient";

const rechargeAPI = {
  getListBank: () => {
    const url = `/api/user/listbank`;
    return axiosClient.get(url);
  },

  getHistoryRecharge: () => {
    const url = `/api/user/recharge/list`;
    return axiosClient.get(url);
  },

  postRecharge: (data) => {
    const url = `/api/user/recharge/add`;
    return axiosClient.post(url, data);
  },
};

export default rechargeAPI;
