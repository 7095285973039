import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function LayoutMy(props) {
  const [custom, setCustom] = useState(0);

  const handleClick = (i) => {
    setCustom(i);
    sendData(i);
  };

  const sendData = (a) => {
    props.parentCallback(a);
  };

  const params = useParams();

  useEffect(() => {
    setCustom(0);
    sendData(0);
  }, [params]);

  return (
    <>
      <div className="home el-tabs el-tabs--card el-tabs--top">
        <div className="el-tabs__header is-top">
          <div className="el-tabs__nav-wrap is-top">
            <div className="el-tabs__nav-scroll">
              <div role="tablist" className="el-tabs__nav is-top">
                {props.content &&
                  props.content.map((item, i) => {
                    return (
                      <div
                        key={i}
                        id="tab-message"
                        className={`${
                          custom === i
                            ? "el-tabs__item is-top is-active"
                            : "el-tabs__item is-top"
                        }`}
                        onClick={() => handleClick(i)}
                      >
                        {item.title}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LayoutMy;
