import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { navbar } from '../data';

function NavbarXS() {
    const [tabXsmn, setTabXsmn] = useState(() => new Date().getDay());
    const [tabXsmt, setTabXsmt] = useState(() => new Date().getDay());
    return (
        <ul className="sub-text">
            {navbar.mienbac && (
                <li>
                    <div className="head">
                        <h3 className="title">Miền Bắc</h3>
                        <select
                            name="xosomienbac"
                            id="xosomienbac"
                        >
                            <option value="toanbo">Toàn bộ</option>
                            <option value="chunhat">Chủ nhật</option>
                            <option value="thuhai">Thứ hai</option>
                            <option value="thuba">Thứ ba</option>
                            <option value="thutu">Thứ tư</option>
                            <option value="thunam">Thứ năm</option>
                            <option value="thusau">Thứ sáu</option>
                            <option value="thubay">Thứ bảy</option>
                        </select>
                    </div>
                    <ul>
                        {navbar.mienbac.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to="/xo-so/xsmb">
                                        <span>{item}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}

            {navbar.trochoi && (
                <li>
                    <div className="head">
                        <h3 className="title">Trò Chơi</h3>
                    </div>
                    <ul>
                        {navbar.trochoi.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to="/game-tai-xiu">
                                        <span>{item}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}

            {navbar.sieutoc && (
                <li>
                    <div className="head">
                        <h3 className="title">Siêu Tốc</h3>
                    </div>
                    <ul>
                        {navbar.sieutoc.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to={item.link}>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}

            {navbar.mega645 && (
                <li>
                    <div className="head">
                        <h3 className="title">Mega 6/45</h3>
                    </div>
                    <ul>
                        {navbar.mega645.map((item, i) => {
                            return (
                                <li key={i}>
                                    <a href="/">
                                        <span>{item}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}

            {navbar.xosoVIP && (
                <li>
                    <div className="head">
                        <h3 className="title">Xổ số VIP</h3>
                    </div>
                    <ul>
                        {navbar.xosoVIP.map((item, i) => {
                            return (
                                <li key={i}>
                                    <a href="/">
                                        <span>{item}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}

            {/* {navbar.miennam && (
                <li>
                    <div className="head">
                        <h3 className="title">Miền Nam</h3>
                        <select
                            name="xosomientrung"
                            id="xosomientrung"
                            onChange={(e) => setTabXsmn(e.target.value)}
                            value={tabXsmn}
                        >
                            <option value="8">Toàn bộ</option>
                            <option value="0">Chủ nhật</option>
                            <option value="1">Thứ hai</option>
                            <option value="2">Thứ ba</option>
                            <option value="3">Thứ tư</option>
                            <option value="4">Thứ năm</option>
                            <option value="5">Thứ sáu</option>
                            <option value="6">Thứ bảy</option>
                        </select>
                    </div>
                    <ul>
                        {navbar.miennam[tabXsmn].map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to={item.link}>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )} */}

            {/* {navbar.mientrung && (
                <li>
                    <div className="head">
                        <h3 className="title">Miền Trung</h3>
                        <select
                            onChange={(e) => setTabXsmt(e.target.value)}
                            value={tabXsmt}
                            name="xosomientrung"
                            id="xosomientrung"
                        >
                            <option value="8">Toàn bộ</option>
                            <option value="0">Chủ nhật</option>
                            <option value="1">Thứ hai</option>
                            <option value="2">Thứ ba</option>
                            <option value="3">Thứ tư</option>
                            <option value="4">Thứ năm</option>
                            <option value="5">Thứ sáu</option>
                            <option value="6">Thứ bảy</option>
                        </select>
                    </div>
                    <ul>
                        {navbar.mientrung[tabXsmn].map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to={item.link}>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )} */}

            {navbar.thailan && (
                <li>
                    <div className="head">
                        <h3 className="title">Thái Lan</h3>
                    </div>
                    <ul>
                        {navbar.thailan.map((item, i) => {
                            return (
                                <li key={i}>
                                    <a href="/">
                                        <span>{item}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            )}
        </ul>
    );
}

export default NavbarXS;
