import React, { useState, useEffect } from "react";
import {
  feedbackBanner,
  iconfb,
  icontele,
  iconzalo,
} from "../../img/importImg";
import { Button } from "reactstrap";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function FeedBack() {
  const [setting, setSetting] = useState();

  useEffect(() => {
    try {
      const fetchGetSettings = async () => {
        const dataSetting = await withdrawAPI.getSetting();
        // console.log(dataSetting);

        if (dataSetting.status === true) {
          setSetting(dataSetting.data[0]);
        }
      };
      fetchGetSettings();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);
  return (
    <div style={{ overflow: "auto", height: "650px" }}>
      <img src={feedbackBanner} alt="" />
      <div className="d-flex flex-column">
        <span className="mt-4" style={{ fontSize: "16px", lineHeight: "35px" }}>
          Trong quá trình chơi gặp phải vấn đề (như nạp/rút tiền, v.v.) vui lòng
          liên hệ với chuyên viên CSKH để chúng tôi giúp bạn giải quyết kịp
          thời!
        </span>
        <Button
          color="primary"
          style={{ width: "150px", fontSize: "16px" }}
          className="py-2 px-4 mt-4"
        >
          CSKH trực tuyến
        </Button>
        <span className="mt-4" style={{ fontSize: "16px", lineHeight: "35px" }}>
          Đối với trò chơi hoặc dịch vụ nếu bạn có đề xuất gì hay xin hãy gửi
          tới chúng tôi.
        </span>
        <span className="mt-4" style={{ fontSize: "16px", lineHeight: "35px" }}>
          Chúng tôi sẽ tham khảo phản hồi của bạn, không ngừng tối ưu hóa sản
          phẩm và dịch vụ, nỗ lực mang đến cho khách hàng sự hài lòng cao nhất,
          xin chân thành cảm ơn !
        </span>
        <Button
          color="primary"
          style={{ width: "150px", fontSize: "16px" }}
          className="py-2 px-4 mt-4"
        >
          Gửi đề xuất
        </Button>
        <span className="mt-4" style={{ fontSize: "16px", lineHeight: "35px" }}>
          Để tất cả thành viên nhận được dịch vụ tốt nhất, đồng thời để bảo vệ
          quyền lợi cho người chơi, chúng tôi cung cấp cho tất cả thành viên
          dịch vụ giám sát chuyển tiền, nếu chuyển tiền bị trì hoãn, hay không
          nhận được tiền hay các vấn đề khác có thể đưa ra khiếu nại! Chúng tôi
          sẽ giúp bạn giải quyết trong thời gian sớm nhất.
        </span>
        <Button
          color="primary"
          style={{ width: "200px", fontSize: "16px" }}
          className="py-2 px-4 mt-4"
        >
          Tôi muốn khiếu nại
        </Button>

        <span className="mt-4" style={{ fontSize: "16px", lineHeight: "35px" }}>
          Cách khác để liên hệ với chúng tôi!
        </span>

        <div className="d-flex w-25 py-4">
          <Link
            to={setting?.link_facebook}
            className="w-25 h-25 pl-4"
            target="_blank"
          >
            <img src={iconfb} alt="" />
          </Link>

          <Link
            to={setting?.link_telegram}
            className="w-25 h-25 pl-4"
            target="_blank"
          >
            <img src={icontele} alt="" />
          </Link>

          <Link
            to={setting?.link_zalo}
            className="w-25 h-25 pl-4"
            target="_blank"
          >
            <img src={iconzalo} alt="" />
          </Link>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default FeedBack;
