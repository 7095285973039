import React, { useState, useEffect } from "react";
import "./Promotion.scss";
import pageAPI from "../../API/pageAPI";
import { Link } from "react-router-dom";
import { baseUrl } from "../../dummy";

function Promotion(args) {
  const [promotion, setPromotion] = useState();
  const [contenKM, setContentKM] = useState();
  const [modal, setModal] = useState(false);
  const auth = sessionStorage.getItem("auth");

  useEffect(() => {
    try {
      const fetchGetPromotion = async () => {
        const result = await pageAPI.getPromotion();
        // console.log(result);

        if (result.status === true) {
          setPromotion(result.data.reverse());
        }
      };

      fetchGetPromotion();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClick = (i) => {
    promotion.forEach((item, j) => {
      if (j === i) {
        setContentKM(item);
        // console.log(item);
      }
    });

    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <div className="act-box p-5">
        <div className="tab_main d-flex flex-column align-items-center justify-content-center py-5">
          {promotion &&
            promotion.map((item, i) => {
              return (
                <div className="py-4 relative" key={i}>
                  <img
                    src={baseUrl + item.image}
                    alt=""
                    onClick={() => handleClick(i)}
                  />

                  {auth && (
                    <Link
                      to={`/my/recharge?id_promotion=${item.id}`}
                      className="button"
                    >
                      Áp dụng ngay
                    </Link>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {modal && (
        <div className="khuyen-mai-bg">
          <div className="transperent" onClick={handleClose}></div>
          <div className="img-km">
            {contenKM && <img src={baseUrl + contenKM.noidung} alt="" />}
          </div>
        </div>
      )}
    </>
  );
}

export default Promotion;
