import React, { useEffect, useState } from "react";
import "./SetUpChip.scss";
import {
  chipSetting,
  chipCustom,
  chip5k,
  chip10k,
  chip25k,
  chip50k,
  chip100k,
  chip200k,
  chip500k,
  chip1000k,
} from "../../../img/importImg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SetUpChip(props) {
  const [valueChipCustom, setValueChipCustom] = useState();
  const [listChipSetUp, setListChipSetUp] = useState([
    { img: chip5k, isSelected: false, isCustom: false, value: 5000 },
    { img: chip10k, isSelected: false, isCustom: false, value: 10000 },
    { img: chip25k, isSelected: false, isCustom: false, value: 25000 },
    { img: chip50k, isSelected: false, isCustom: false, value: 50000 },
    { img: chip100k, isSelected: false, isCustom: false, value: 100000 },
    { img: chip200k, isSelected: false, isCustom: false, value: 200000 },
    { img: chip500k, isSelected: false, isCustom: false, value: 500000 },
    { img: chip1000k, isSelected: false, isCustom: false, value: 1000000 },
  ]);
  const [listChipSelected, setListChipSelected] = useState([]);
  const [quantitySelectedChip, setQuantitySelectedChip] = useState(0);
  const [isValueCustomChip, setIsValueCustomChip] = useState(true);

  const handleCustomChip = () => {
    if (
      Number(valueChipCustom) < 0 ||
      Number(valueChipCustom) > 100 ||
      !Number.isInteger(Number(valueChipCustom))
    ) {
      return toast.error(
        "Giá trị của chip phải lớn hơn 0, nhỏ hơn 100 và là số nguyên!",
        {
          theme: "light",
        }
      );
    }

    listChipSetUp.push({
      img: chipCustom,
      isSelected: false,
      isCustom: true,
      value: Number(valueChipCustom),
    });

    setIsValueCustomChip(false);
  };

  const handleSelectedChip = (value) => {
    const listChipNew = listChipSetUp.map((item) => {
      if (item.value === value) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    });

    const listNewChipSelected = listChipNew.filter(
      (item) => item.isSelected === true
    );

    if (listNewChipSelected.length > 7) return;

    setListChipSelected(listNewChipSelected);
    setQuantitySelectedChip(listNewChipSelected.length);
    setListChipSetUp(listChipNew);
  };

  const handleCancel = () => {
    props.callbackSetupChip();
  };

  const handleConfirm = () => {
    if (listChipSelected.length !== 7)
      return toast.error("Vui lòng chọn đủ 7 chip!", {
        theme: "light",
      });
    props.callbackListChip(listChipSelected);
  };

  return (
    <div className="background-opacity background-setup__chip">
      <div className="container-modal">
        <div className="header-setup__chip">Cài đặt chip</div>
        <div className="body-setup__chip">
          <div className="title-chip">
            Vui lòng chọn {quantitySelectedChip}/7 chips
          </div>
          <div className="list-chip__setup">
            {listChipSetUp.map((item, i) => {
              return (
                <div
                  key={i}
                  className="item"
                  onClick={() => handleSelectedChip(item.value)}
                >
                  {!item.isCustom ? (
                    <img src={item.img} alt="" />
                  ) : (
                    <div className="custom-chip__isValue">
                      <span>{item.value}</span>
                    </div>
                  )}

                  {item.isSelected ? (
                    <div className="active"></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {isValueCustomChip && (
          <div className="custom-chip">
            <div className="title-custom">Chip tuỳ chỉnh</div>
            <img src={chipSetting} alt="" />
            <div className="input-custom_chip mt-2">
              <div className="input">
                <input
                  type="number"
                  className="w-75"
                  min={1}
                  max={999}
                  onChange={(e) => setValueChipCustom(e.target.value)}
                />
                <span>M</span>
              </div>
              <button onClick={handleCustomChip}>OK</button>
            </div>
          </div>
        )}
        <div className="button-setting__chips">
          <div className="button button-cancel" onClick={handleCancel}>
            Huỷ bỏ
          </div>
          <div className="button button-confirm" onClick={handleConfirm}>
            Xác nhận
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SetUpChip;
