import axiosClient from "./axiosClient";

const withdrawAPI = {
  getSetting: () => {
    const url = `/api/user/setting`;
    return axiosClient.get(url);
  },

  getHistoryWitdraw: () => {
    const url = `/api/user/historyrut`;
    return axiosClient.get(url);
  },

  postAddBanking: (data) => {
    const url = `/api/user/addbanking`;
    return axiosClient.post(url, data);
  },

  postWithdraw: (data) => {
    const url = `/api/user/withdraw`;
    return axiosClient.post(url, data);
  },

  getListBank: () => {
    const url = `/api/user/list/bank`;
    return axiosClient.get(url);
  },
};

export default withdrawAPI;
