import React, { useState } from "react";
import { hotGameBubbleGif, mienbac, taixiu } from "../../img/importImg";
import "./HotGameBubble.scss";
import { Link } from "react-router-dom";

function HotGameBubble() {
  const [showGameList, setShowGameList] = useState(false);

  const handleClick = () => {
    setShowGameList(!showGameList);
  };

  return (
    <div className="hot-game__bubble">
      <div className={`list-game__hot ${showGameList ? "show" : ""}`}>
        <Link to="/game-tai-xiu">
          <img src={taixiu} alt="" width={80} />
        </Link>
        <Link to="/xo-so/xsmb">
          <img src={mienbac} alt="" width={80} />
        </Link>
      </div>

      <img src={hotGameBubbleGif} alt="" width={150} onClick={handleClick} />
    </div>
  );
}

export default HotGameBubble;
