import React, { useState, useEffect } from 'react';
import transferAPI from '../../API/transferAPI';
import accountAPI from '../../API/account';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input, Button } from 'reactstrap';
import { tranferIcon } from '../../img/importImg';
import ReactLoading from 'react-loading';

const VND = (number) =>
    new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
    }).format(number);

const getUserInfo = (cb) => accountAPI.getUserInfo().then((res) => cb(res));

function Tranfer() {
    const [userInfo, setUserinfo] = useState({});
    useEffect(() => {
        getUserInfo(setUserinfo);
    }, []);

    const handleWithdraw = () => {
        const { moneyBac } = userInfo;
        if (!moneyBac) return toast.warn('Ví sảnh đã hết!');
        accountAPI
            .withdraw()
            .then(({ status, msg }) => {
                if (!status) return toast.error(msg);
                getUserInfo(setUserinfo);
                toast.success(msg);
            })
            .catch(() => toast.error('Có lỗi xảy ra!'));
    };

    return (
        <>
            <div
                className="d-flex"
                style={{ fontSize: '20px' }}
            >
                <p className="pr-5">Ví cá nhân: {VND(userInfo?.money)}</p>
                <p>Ví sảnh: {VND(userInfo?.moneyBac)}</p>
            </div>
            <Button
                color="primary"
                className="px-4 py-2 mt-4"
                style={{ fontSize: '14px', borderRadius: '5px' }}
                onClick={handleWithdraw}
            >
                Rút tiền về tài khoản
            </Button>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default Tranfer;
