import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import accountAPI from "../../API/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoMy from "./InfoMy";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function ChangePWTrans() {
  const [passwordOld, setpasswordOld] = useState();
  const [newPassword, setnewPassword] = useState();
  const [confirmPW, setConfirmPW] = useState();
  const [load, setLoad] = useState(false);

  const handleReset = () => {
    setConfirmPW("");
    setnewPassword("");
    setpasswordOld("");
  };

  const [user, setUser] = useState();

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const result = await accountAPI.getUserInfo();
        console.log(result);
        setUser(result);
      };

      fetchGetUserInfo();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, [load]);

  const handleChangePW = async () => {
    try {
      if (user?.isPWV2) {
        if (!newPassword || !passwordOld || !confirmPW) {
          return toast.error("Không được để trống!", { theme: "light" });
        }
      } else {
        if (!newPassword || !confirmPW) {
          return toast.error("Không được để trống!", { theme: "light" });
        }
      }

      if (newPassword !== confirmPW) {
        return toast.error(
          "Mật khẩu rút tiền mới và mật khẩu xác nhận rút tiền không trùng khớp!",
          { theme: "light" }
        );
      }

      const data = {
        newPassword: newPassword,
        password_user: passwordOld,
        type: user?.isPWV2,
      };

      const result = await accountAPI.putChangePWTrans(data);
      //   console.log(result);
      if (result.status === true) {
        toast.success(result.msg || "Thay đổi mật khẩu rút tiền thành công!", {
          theme: "light",
        });
        setLoad(!load);
        handleReset();
      } else {
        toast.error(result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  };

  return (
    <>
      <InfoMy />
      <div className="d-flex flex-column align-items-center justify-content-center pt-4">
        {user && user.isPWV2 ? (
          <>
            <div className="w-25 py-3">
              <span>
                <span style={{ color: "red" }}>*</span> Mật khẩu rút tiền cũ:
              </span>
              <Input
                className="mt-2"
                style={{ height: "35px", fontSize: "14px" }}
                placeholder="Vui lòng nhập mật khẩu rút tiền cũ"
                type="password"
                onChange={(e) => setpasswordOld(e.target.value)}
                value={passwordOld}
              ></Input>
            </div>

            <div className="w-25 py-3">
              <span>
                <span style={{ color: "red" }}>*</span> Mật khẩu rút tiền mới:
              </span>
              <Input
                className="mt-2"
                style={{ height: "35px", fontSize: "14px" }}
                placeholder="Vui lòng nhập mật khẩu rút tiền mới"
                type="password"
                onChange={(e) => setnewPassword(e.target.value)}
                value={newPassword}
              ></Input>
            </div>

            <div className="w-25 py-3">
              <span>
                <span style={{ color: "red" }}>*</span> Xác nhận mật khẩu rút
                tiền mới:
              </span>
              <Input
                className="mt-2"
                style={{ height: "35px", fontSize: "14px" }}
                placeholder="Xác nhận mật khẩu rút tiền mới"
                type="password"
                onChange={(e) => setConfirmPW(e.target.value)}
                value={confirmPW}
              ></Input>
            </div>
          </>
        ) : (
          <>
            <div className="w-25 py-3">
              <span>
                <span style={{ color: "red" }}>*</span> Mật khẩu rút tiền:
              </span>
              <Input
                className="mt-2"
                style={{ height: "35px", fontSize: "14px" }}
                placeholder="Vui lòng nhập mật khẩu rút tiền"
                type="password"
                onChange={(e) => setnewPassword(e.target.value)}
                value={newPassword}
              ></Input>
            </div>

            <div className="w-25 py-3">
              <span>
                <span style={{ color: "red" }}>*</span> Xác nhận mật khẩu rút
                tiền:
              </span>
              <Input
                className="mt-2"
                style={{ height: "35px", fontSize: "14px" }}
                placeholder="Xác nhận mật khẩu rút tiền"
                type="password"
                onChange={(e) => setConfirmPW(e.target.value)}
                value={confirmPW}
              ></Input>
            </div>
          </>
        )}

        <div className="d-flex">
          <Button
            color="primary"
            style={{ height: "35px", fontSize: "14px" }}
            className="mt-4"
            onClick={handleChangePW}
          >
            Gửi
          </Button>

          <Button
            color="secondary"
            style={{ height: "35px", fontSize: "14px" }}
            className="mt-4 ml-4"
            onClick={handleReset}
          >
            Cài đặt lại
          </Button>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default ChangePWTrans;
