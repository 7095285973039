import Home from "../pages/home/Home";
import Register from "../pages/account/Register";
import About from "../pages/about/About";
import DefaultLayout from "../components/DefaultLayout/DefaultLayout";
import My from "../pages/my/My";
import Promotion from "../pages/promotion/Promotion";
import Support from "../pages/Support/Support";
import Game from "../pages/taixiu/taixiu";
import XoSo from "../pages/xoso/XoSo";

// Public Router

// let defaultTitle = "Casino";

const publicRoutes = [
  { path: "/", title: "Trang chủ", component: Home, layout: DefaultLayout },
  {
    path: "/register",
    title: "Đăng ký",
    component: Register,
    layout: DefaultLayout,
  },
  {
    path: "/about",
    title: "Về chúng tôi",
    component: About,
    layout: DefaultLayout,
  },
  {
    path: "/my/:param",
    title: "Thông tin của bạn",
    component: My,
    layout: DefaultLayout,
    requiresAuth: true,
  },
  {
    path: "/my",
    title: "Thông tin của bạn",
    component: My,
    layout: DefaultLayout,
    requiresAuth: true,
  },
  {
    path: "/promotion",
    title: "Khuyến mãi",
    component: Promotion,
    layout: DefaultLayout,
  },

  {
    path: "/game-tai-xiu",
    title: "Game Tài Xỉu",
    component: Game,
    layout: DefaultLayout,
  },
  {
    path: "/xo-so/:id",
    title: "Xổ Số",
    component: XoSo,
    layout: DefaultLayout,
  },
  {
    path: "/support",
    title: "Chăm sóc khách hàng",
    component: Support,
  },
];

// Private Router
const privateRoutes = [];

export { publicRoutes, privateRoutes };
