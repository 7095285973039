import React, { useEffect, useState } from "react";
import "./My.scss";
import { listMy } from "../../dummy";
import MyMainContent from "../../components/MyMainContent/MyMainContent";
import { useHref, useParams, Link } from "react-router-dom";

function My() {
  const [index, setIndex] = useState();
  const [content, setContent] = useState();
  const handleActive = (i, data) => {
    setIndex(i);
    setContent(data);
  };

  const params = useParams();
  const paramsItem = params.param;

  useEffect(() => {
    const contentNew = [];
    listMy.forEach((item, i) => {
      if (item.params === paramsItem) {
        contentNew.push(item.data);
      }
    });

    const [contentFix] = contentNew;
    setContent(contentFix);
  }, [paramsItem]);

  return (
    <div className="el-my-style">
      <section className="el-container content-view is-vertical">
        <section className="el-container style">
          <aside className="el-aside">
            <ul role="menubar" className="el-menu">
              {listMy.map((item, i) => {
                return (
                  <Link
                    key={i}
                    to={`/my/${item.params}`}
                    onClick={() => handleActive(i, item.data)}
                  >
                    <li
                      role="menuitem"
                      className={`${
                        String(i) === String(index)
                          ? "el-menu-item is-active"
                          : "el-menu-item"
                      }`}
                    >
                      <img
                        src={`${
                          String(i) === String(index)
                            ? item.iconActive
                            : item.icon
                        }`}
                        alt="icon"
                      />
                      <span>{item.title}</span>
                    </li>
                  </Link>
                );
              })}
            </ul>
          </aside>
          <MyMainContent content={content} params={paramsItem} />
        </section>
      </section>
    </div>
  );
}

export default My;
