import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { iconNext, iconPrev } from "../../img/importImg";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function HistoryUser(props) {
  const [modal, setModal] = useState(false);
  const [soTrang, setSoTrang] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const toggle = () => {
    setModal(!modal);
    props.callbackIsModal();
  };

  const callbackFunctionSoTrang = (data) => {
    props.callbackFunctionSoTrang(data);
  };

  useEffect(() => {
    setModal(props.isModalHistoryUser);
  }, [modal]);

  return (
    <div className="history-user__sicbo">
      <Modal
        isOpen={modal}
        toggle={toggle}
        {...props}
        style={{ maxWidth: "1080px" }}
        centered
      >
        <ModalHeader
          toggle={toggle}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "18px",
          }}
        >
          Tài Xỉu
        </ModalHeader>
        <ModalBody>
          <div className="betlog-wrap" style={{ maxWidth: "1050px" }}>
            <div className="bet-log">
              <div
                className="item table-name row"
                style={{ width: "100%", marginLeft: "1px" }}
              >
                <div className="col-1">Phiên</div>
                <div className="col-3">Thời gian</div>
                <div className="col-2">Thông tin đặt cược</div>
                <div className="col-2">Tổng số tiền</div>
                <div className="col-2">Thắng thua</div>
                <div className="col-2">Trạng thái</div>
              </div>
              <div className="b-content">
                {props.historyUser ? (
                  props.historyUser.map((item, i) => {
                    return (
                      <div className="b-content-item" key={i}>
                        <div className="item table-name row content-table">
                          <div className="col-1">{item.phien}</div>
                          <div className="col-3">{item.date}</div>
                          <div className="col-2">{item.cuaDat}</div>
                          <div className="col-2">
                            {VND.format(Number(item.money))}
                          </div>
                          <div className="col-2">
                            {VND.format(Number(item.win))}
                          </div>
                          <div className="col-2">
                            {item.thanhtoan == 1 ? "Đã xử lý" : "Chờ mở giải"}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="no-data">
                    <p className="more">Không có thêm dữ liệu</p>
                  </div>
                )}
              </div>
              <div className="el-pagination is-background">
                <button
                  className="btn-prev"
                  disabled={props.soTrang === 1}
                  onClick={() => callbackFunctionSoTrang(props.soTrang - 1)}
                >
                  <img src={iconPrev} alt="" style={{ width: "8px" }} />
                </button>
                <ul className="el-pager">
                  <li className="number active">{props.soTrang}</li>
                </ul>
                <button
                  className="btn-next"
                  //   disabled={props.soTrang + 1 > props.totalPage}
                  onClick={() => callbackFunctionSoTrang(props.soTrang + 1)}
                >
                  <img src={iconNext} alt="" style={{ width: "8px" }} />
                </button>
              </div>
              <div className="el-dialog__wrapper"></div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default HistoryUser;
