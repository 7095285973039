import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Banner from '../../components/Banner/Banner';
import HomeHotGame from '../../components/HomeHotGame/HomeHotGame';
import HomeAbout from '../../components/HomeAbout/HomeAbout';
import Adjumpbox from '../../components/Adjumpbox/Adjumpbox';
import Notification from '../../components/Notification/Notification';
import './Home.scss';

function Home() {
    const { t } = useTranslation('translation');
    const changeLanguage = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
    };

    return (
        <div>
            <Banner />
            {/* <Notification /> */}
            <HomeHotGame />
            <HomeAbout />
            {/* <div>
        <h1>{t("title")}</h1>
        <select onChange={changeLanguage}>
          <option value="eng">English</option>
          <option value="vie">Tiếng Việt</option>
        </select>
      </div> */}
        </div>
    );
}

export default Home;
