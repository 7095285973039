import React, { useEffect, useState } from "react";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "../home/Home";

function Service() {
  const [settings, setSettings] = useState();

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result.data[0]);

        if (result.status === true) {
          toast.success(
            "Vui lòng đợi chút trong thời gian chúng tôi kết nối!",
            {
              theme: "light",
            }
          );
          setSettings(result.data[0]);
        } else {
          return toast.error(result.msg, { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);

  function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
    console.log("LiveChatWidget.onNewEvent", event);
  }

  return (
    <>
      <Home />
      {settings && (
        <LiveChatWidget license={settings.id_livechat} visibility="maximized" />
      )}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Service;
