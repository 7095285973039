import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import accountAPI from "../../API/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoMy from "./InfoMy";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function validateInput(input) {
  const regex = /^[a-z0-9]{6,11}$/;
  return regex.test(input);
}

function ChangePW() {
  const [passwordOld, setpasswordOld] = useState();
  const [newPassword, setnewPassword] = useState();
  const [confirmPW, setConfirmPW] = useState();

  const handleReset = () => {
    setConfirmPW("");
    setnewPassword("");
    setpasswordOld("");
  };

  const handleChangePW = async () => {
    try {
      if (!newPassword || !passwordOld || !confirmPW) {
        return toast.error("Không được để trống!", { theme: "light" });
      }

      if (newPassword !== confirmPW) {
        return toast.error(
          "Mật khẩu mới và mật khẩu xác nhận không trùng khớp!",
          { theme: "light" }
        );
      }

      if (!validateInput(newPassword)) {
        return toast.error(
          "Mật khẩu phải có từ 6-11 kí tự, chỉ bao gồm chữ thường và ít nhất một số!",
          {
            theme: "light",
          }
        );
      }

      const data = {
        newPassword: newPassword,
        passwordOld: passwordOld,
      };

      const result = await accountAPI.putChangePW(data);
      //   console.log(result);
      if (result.status === true) {
        toast.success(result.msg || "Thay đổi mật khẩu thành công!", {
          theme: "light",
        });

        handleReset();
      } else {
        toast.error(result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  };

  return (
    <>
      <InfoMy />
      <div className="d-flex flex-column align-items-center justify-content-center pt-4">
        <div className="w-25 py-3">
          <span>
            <span style={{ color: "red" }}>*</span> Mật khẩu đăng nhập hiện tại:
          </span>
          <Input
            className="mt-2"
            style={{ height: "35px", fontSize: "14px" }}
            placeholder="Vui lòng nhập mật khẩu hiện tại"
            type="password"
            onChange={(e) => setpasswordOld(e.target.value)}
            value={passwordOld}
          ></Input>
        </div>

        <div className="w-25 py-3">
          <span>
            <span style={{ color: "red" }}>*</span> Mật khẩu mới:
          </span>
          <Input
            className="mt-2"
            style={{ height: "35px", fontSize: "14px" }}
            placeholder="Vui lòng nhập mật khẩu mới"
            type="password"
            onChange={(e) => setnewPassword(e.target.value)}
            value={newPassword}
          ></Input>
        </div>

        <div className="w-25 py-3">
          <span>
            <span style={{ color: "red" }}>*</span> Xác nhận mật khẩu mới:
          </span>
          <Input
            className="mt-2"
            style={{ height: "35px", fontSize: "14px" }}
            placeholder="Xác nhận mật khẩu mới"
            type="password"
            onChange={(e) => setConfirmPW(e.target.value)}
            value={confirmPW}
          ></Input>
        </div>

        <div className="d-flex">
          <Button
            color="primary"
            style={{ height: "35px", fontSize: "14px" }}
            className="mt-4"
            onClick={handleChangePW}
          >
            Gửi
          </Button>

          <Button
            color="secondary"
            style={{ height: "35px", fontSize: "14px" }}
            className="mt-4 ml-4"
            onClick={handleReset}
          >
            Cài đặt lại
          </Button>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}

export default ChangePW;
