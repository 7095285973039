import React, { useEffect, useState } from 'react';
import { taiApp, cskh, choiThu, lhChungToi, scrollTop, telegramIcon } from '../../img/importImg';
import withdrawAPI from '../../API/withdrawAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

function BoxRight() {
    const [settings, setSettings] = useState();
    const [telegramLink, setTelegramLink] = useState();

    const handleClickToTop = () => {
        const scrollToTop = () => {
            const currentPosition = window.pageYOffset;
            if (currentPosition > 0) {
                window.scrollTo(0, currentPosition - currentPosition / 8);
                requestAnimationFrame(scrollToTop);
            }
        };
        scrollToTop();
    };

    useEffect(() => {
        try {
            const fetchGetSetting = async () => {
                const result = await withdrawAPI.getSetting();
                // console.log(result.data[0]);

                if (result.status === true) {
                    const telegram = result.data[0].link_telegram.split(';');
                    setTelegramLink(telegram);
                    // console.log(telegram);
                    setSettings(result.data[0]);
                } else {
                    return toast.error('Có lỗi xảy ra!', { theme: 'light' });
                }
            };

            fetchGetSetting();
        } catch (error) {
            toast.error('Có lỗi xảy ra!', { theme: 'light' });
        }
    }, []);

    return (
        <div className="fix_box-right">
            <div className="shortcut-box">
                <ul className="shortcut-box_list">
                    {/* <li className="shortcut-box_item line_bottom">
                        <Link
                            to="https://api.268bet.com/static/268bet.apk"
                            target="_blank"
                        >
                            <span className="icon">
                                <img
                                    src={taiApp}
                                    alt=""
                                />
                            </span>
                            <span className="text">TẢI APP</span>
                        </Link>
                    </li> */}
                    <li className="shortcut-box_item line_bottom">
                        <Link to="/support">
                            <span className="icon">
                                <img
                                    src={cskh}
                                    alt=""
                                />
                            </span>
                            <span className="text">CSKH</span>
                        </Link>
                    </li>
                    {/* <li className="shortcut-box_item line_bottom">
            <a href="/">
              <span className="icon">
                <img src={choiThu} alt="" />
              </span>
              <span className="text">CHƠI THỬ</span>
            </a>
          </li> */}
                    {telegramLink && (
                        <li className="shortcut-box_item line_bottom">
                            <Link
                                to={telegramLink[0]}
                                target="_blank"
                            >
                                <span className="icon">
                                    <img
                                        src={telegramIcon}
                                        alt=""
                                        width={30}
                                    />
                                </span>
                                <span className="text">TELEGRAM</span>
                            </Link>
                        </li>
                    )}
                    {/* <li className="shortcut-box_item">
            <a href="/">
              <span className="icon close-button right">
                <img src="" alt="" />
              </span>
            </a>
          </li> */}
                    <li
                        className="back-top"
                        id="backtotop"
                        onClick={handleClickToTop}
                    >
                        <a>
                            <img
                                src={scrollTop}
                                alt=""
                                style={{ paddingTop: '5px' }}
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default BoxRight;
