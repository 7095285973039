import React, { useState, useEffect } from "react";
import rechargeAPI from "../../API/rechargeAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function HistoryFeedBack() {
  const [historyRecharge, setHistoryRecharge] = useState([]);

  //   useEffect(() => {
  //     try {
  //       const fetchGetHistoryRecharge = async () => {
  //         const result = await rechargeAPI.getHistoryRecharge();
  //         console.log(result);

  //         if (result.status === true) {
  //           setHistoryRecharge(result.data);
  //         } else {
  //           toast.error("Có lỗi xảy ra!", { theme: "light" });
  //         }
  //       };

  //       fetchGetHistoryRecharge();
  //     } catch (error) {
  //       return toast.error("Có lỗi xảy ra!", { theme: "light" });
  //     }
  //   }, []);

  return (
    <div>
      {historyRecharge.length > 0 ? (
        <table className="table table-bordered rounded table-hover">
          <thead className="text-center" style={{ backgroundColor: "#e8f0fc" }}>
            <th>Loại hình</th>
            <th>Miêu tả nội dung</th>
            <th>Thời gian gửi lên</th>
            <th>Thời gian phản hồi cuối cùng</th>
            <th>Trạng thái</th>
            <th>Thao tác</th>
          </thead>
          <tbody>
            {historyRecharge.map((item, i) => {
              return (
                <tr key={i} className="text-center">
                  <td>{item.create_at}</td>
                  <td>Chuyển tiền ngân hàng</td>
                  <td>{VND.format(item.amount)}</td>
                  <td>0đ</td>
                  <td>{item.descriptions}</td>
                  <td>Chi tiết</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <>
          <table className="table table-bordered rounded">
            <thead
              className="text-center"
              style={{ backgroundColor: "#e8f0fc" }}
            >
              <th>Loại hình</th>
              <th>Miêu tả nội dung</th>
              <th>Thời gian gửi lên</th>
              <th>Thời gian phản hồi cuối cùng</th>
              <th>Trạng thái</th>
              <th>Thao tác</th>
            </thead>
          </table>
          <span className="d-flex align-items-center justify-content-center py-2">
            Không có dữ liệu
          </span>
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default HistoryFeedBack;
