import React from "react";
import TopHeader from "./TopHeader/TopHeader";
import CenterHeader from "./CenterHeader/CenterHeader";
import BottomHeader from "./BottomHeader/BottomHeader";
// import "./Header.scss";

function Header() {
  return (
    <header>
      <TopHeader />
      <CenterHeader />
      <BottomHeader />
    </header>
  );
}

export default Header;
