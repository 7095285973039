import React, { useState, useEffect } from "react";
import {
  titleReg,
  subTitle,
  validCode,
  code1238,
  code1317,
  code1432,
  code1632,
  code1815,
  code2478,
  code2697,
  code3123,
  code3169,
  code4111,
  code5322,
  code6659,
  code8863,
  code9549,
} from "../../img/importImg";
import accountAPI from "../../API/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

function Register() {
  const [randomCode, setRandomCode] = useState();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [hoVaTen, setHoVaTen] = useState();
  const [sdt, setSdt] = useState();
  const [userCode, setUserCode] = useState();
  const [checkBox, setCheckBox] = useState(false);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get("ref");

  const [maDaiLy, setMaDaiLy] = useState(ref ? ref : "");

  const codeMap = new Map([
    [code1238, 1238],
    [code1317, 1317],
    [code1432, 1432],
    [code1632, 1632],
    [code1815, 1815],
    [code2478, 2478],
    [code2697, 2697],
    [code3123, 3123],
    [code3169, 3169],
    [code4111, 4111],
    [code5322, 5322],
    [code6659, 6659],
    [code8863, 8863],
    [code9549, 9549],
  ]);

  function randomFromArray(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  function getRandomImageAndCode(map) {
    const entries = Array.from(map.entries());
    const randomEntry = randomFromArray(entries);
    return {
      image: randomEntry[0],
      code: randomEntry[1],
    };
  }

  function validateInput(input) {
    const regex = /^[a-z0-9]{6,11}$/;
    return regex.test(input);
  }

  useEffect(() => {
    setRandomCode(getRandomImageAndCode(codeMap));
  }, []);

  const handleReset = () => {
    setMaDaiLy("");
    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setHoVaTen("");
    setSdt("");
    setUserCode("");
  };

  const handleRegister = async (e) => {
    try {
      e.preventDefault();
      if (
        !userName ||
        !password ||
        !userCode ||
        !confirmPassword ||
        !hoVaTen ||
        !sdt ||
        !checkBox
      ) {
        return toast.error("Không được để trống!", { theme: "light" });
      } else if (userCode !== String(randomCode.code)) {
        return toast.error("Mã xác nhận không hợp lệ!", { theme: "light" });
      } else if (!validateInput(userName) || !validateInput(password)) {
        return toast.error(
          "Tên đăng nhập hoặc mật khẩu phải có từ 6-11 kí tự, chỉ bao gồm chữ thường và ít nhất một số!",
          {
            theme: "light",
          }
        );
      } else if (password !== confirmPassword) {
        return toast.error(
          "Xác nhận mật khẩu không trùng khớp với mật khẩu đăng nhập!",
          { theme: "light" }
        );
      } else if (hoVaTen.length > 40) {
        return toast.error("Họ và tên không hợp lệ!", { theme: "light" });
      } else if (sdt.length > 15) {
        return toast.error("Số điện thoại không hợp lệ!", { theme: "light" });
      }

      const data = {
        username: userName,
        password: password,
        fullname: hoVaTen,
        sdt: sdt,
        invite: maDaiLy,
        userCode: userCode,
      };

      const result = await accountAPI.postRegister(data);

      if (result.status === "ok") {
        sessionStorage.setItem("auth", result.auth);
        toast.success(result.message, { theme: "light" });
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else if (result.status === false) {
        return toast.error(result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  };

  return (
    <div>
      <section className="page-register">
        <div className="container">
          <div className="head-register">
            <img src={titleReg} alt="" />
          </div>
          <form className="wrap-register" onSubmit={handleRegister}>
            <div className="title">
              <img src={subTitle} alt="" />
            </div>
            <div className="box-form">
              <div className="row col-mar-30">
                {ref ? (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <span className="label">Mã đại lý</span>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        placeholder="Nhập mã giới thiệu hoặc bỏ qua nếu không có người giới thiệu bạn"
                        value={maDaiLy}
                        onChange={(e) => {
                          setMaDaiLy(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <span className="label">Mã đại lý</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nhập mã giới thiệu hoặc bỏ qua nếu không có người giới thiệu bạn"
                        value={maDaiLy}
                        onChange={(e) => {
                          setMaDaiLy(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="col-lg-6">
                  <div className="form-group required">
                    <span className="label">Tên đăng nhập</span>
                    <input
                      type="text"
                      className="form-control"
                      value={userName}
                      placeholder="Số tài khoản phải là 6-11 kí tự dưới dạng kết hợp giữa số và chữ cái thường"
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group required">
                    <span className="label">Mật khẩu đăng nhập</span>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      placeholder="Mật khẩu phải dài 6-11 ký tự và phải chứa một số kết hợp từ và chữ cái thường"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group required">
                    <span className="label">Xác nhận mật khẩu</span>
                    <input
                      type="password"
                      className="form-control"
                      value={confirmPassword}
                      placeholder="Vui lòng nhập mật khẩu giống như trên"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group required">
                    <span className="label">Họ và tên</span>
                    <input
                      type="text"
                      className="form-control"
                      value={hoVaTen}
                      placeholder="Tên phải giống với tên của tài khoản ngân hàng bạn đã sử dụng để rút tiền, Nếu không thì không thể rút"
                      onChange={(e) => {
                        setHoVaTen(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group required">
                    <span className="label">Số điện thoại</span>
                    <input
                      type="text"
                      className="form-control"
                      value={sdt}
                      placeholder="Định dạng số điện thoại di động: 0962xxxxxx"
                      onChange={(e) => {
                        setSdt(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group validate">
                    <span className="label">Mã xác nhận</span>
                    <input
                      type="text"
                      className="form-control"
                      value={userCode}
                      onChange={(e) => {
                        setUserCode(e.target.value);
                      }}
                    />
                    <div className="valid-code">
                      <img src={randomCode?.image} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="check1" className="i-check">
                      <input
                        type="checkbox"
                        id="check1"
                        onChange={(e) => {
                          setCheckBox(e.target.checked);
                        }}
                      />
                      <i></i>
                      <span>
                        Tôi 18 tuổi trở lên, chấp nhận và đồng ý tất cả chính
                        sách, điều khoản và điều kiện, luật lệ, “chính sách
                        thành viên”.
                      </span>
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="btn-form">
                    <button type="submit" className="btn-pri item">
                      <span>Hoàn tất</span>
                    </button>
                    <button
                      type="button"
                      className="btn-second item"
                      onClick={handleReset}
                    >
                      <span>Cài lại</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Register;
