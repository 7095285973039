import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import accountAPI from "../../API/account";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function Withdraw() {
  const [user, setUser] = useState();
  const [money, setmoney] = useState();
  const [password, setpassword] = useState();
  const [setting, setSetting] = useState();

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const result = await accountAPI.getUserInfo();
        const dataSetting = await withdrawAPI.getSetting();
        // console.log(dataSetting);
        // console.log(result);
        setUser(result);
        if (dataSetting.status === true) {
          setSetting(dataSetting.data[0]);
        } else {
          return toast.error(result.msg, { theme: "light" });
        }

        // Không có status
        // if (result.status === true) {
        // }
      };
      fetchGetUserInfo();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);

  const handleWithdraw = async () => {
    try {
      if (!password || !money) {
        return toast.error("Không được để trống!", { theme: "light" });
      }

      if (setting.money_need_recharge) {
        if (money < setting.money_need_recharge) {
          return toast.error(
            `Số tiền rút tối thiểu là ${VND.format(
              setting.money_need_recharge
            )}!`,
            {
              theme: "light",
            }
          );
        }
      }

      const data = {
        password: password,
        money: money,
      };

      const result = await withdrawAPI.postWithdraw(data);
      console.log(result);
      if (result.status === true) {
        toast.success(
          result.msg || "Đã thực hiện rút tiền, vui lòng chờ duyệt!",
          { theme: "light" }
        );
      } else {
        toast.error(result.msg, { theme: "light" });
      }
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="w-25 py-3">
        <span>Số dư hiện tại: </span>
        <Input
          className="mt-2"
          style={{ height: "35px", fontSize: "14px" }}
          disabled={true}
          value={VND.format(user?.money)}
        ></Input>
      </div>

      <div className="w-25 py-3">
        <div className="d-flex">
          <span style={{ color: "red" }}>*</span> Số tiền rút:{" "}
          <span
            className="ml-2"
            style={money ? { display: "block" } : { display: "none" }}
          >
            {VND.format(money)}
          </span>
        </div>
        <Input
          className="mt-2"
          style={{ height: "35px", fontSize: "14px" }}
          placeholder="Vui lòng nhập số tiền muốn rút"
          type="number"
          onChange={(e) => setmoney(e.target.value)}
        ></Input>
      </div>

      <div className="w-25 py-3">
        <span>Lệ phí rút tiền: </span>
        <Input
          className="mt-2"
          style={{ height: "35px", fontSize: "14px" }}
          disabled={true}
          value={`${setting?.fee}%`}
        ></Input>
      </div>

      <div className="w-25 py-3">
        <span>Phí thủ tục: </span>
        <Input
          className="mt-2"
          style={{ height: "35px", fontSize: "14px" }}
          disabled={true}
          value={`${money ? VND.format((setting?.fee * money) / 100) : "0đ"} `}
        ></Input>
      </div>

      <div className="w-25 py-3">
        <span>
          <span style={{ color: "red" }}>*</span> Mật khẩu rút tiền:{" "}
        </span>
        <Input
          className="mt-2"
          style={{ height: "35px", fontSize: "14px" }}
          placeholder="Vui lòng nhập mật khẩu rút tiền"
          type="password"
          onChange={(e) => setpassword(e.target.value)}
        ></Input>
      </div>

      <Button
        color="primary"
        style={{ height: "35px", fontSize: "14px" }}
        className="mt-4"
        onClick={handleWithdraw}
      >
        Rút tiền
      </Button>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Withdraw;
