import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gameAPI from '../../../../API/gameAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import { baseUrl } from '../../../../dummy';

function NavbarCasino(props) {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (type, gameid, providercode, status) => {
        try {
            const auth = sessionStorage.getItem('auth');
            if (!auth) {
                return toast.error('Vui lòng đăng nhập để trải nghiệm game!', {
                    theme: 'light',
                });
            }
            if (!status)
                return toast.error('Hiện đang bảo trì, mời bạn quay lại sau!', {
                    theme: 'light',
                });
            setIsLoading(true);

            const data = {
                type: type,
                gameid: gameid === 'underfined' ? null : gameid,
                providercode: providercode,
                html5: '0',
            };

            const result = await gameAPI.postLaunchGame(data);
            setIsLoading(false);
            // console.log(result);

            if (result.status === true) {
                if (result.result.gameUrl === null) {
                    return toast.error('Hiện đang bảo trì, mời bạn quay lại sau!', {
                        theme: 'light',
                    });
                } else window.open(`${result.result.gameUrl}`, '_blank');
            } else {
                toast.error(result.msg, {
                    theme: 'light',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    // console.log(props);

    return (
        <>
            {isLoading && (
                <div className="overlay d-flex justify-content-center align-items-center">
                    <ReactLoading
                        type="spinningBubbles"
                        color="grey"
                        height={300}
                        width={300}
                        className="loader"
                    />
                </div>
            )}

            <ul className={`${props.center ? 'center' : ''}`}>
                {props.data &&
                    props.data.map((item, i) => {
                        return (
                            <li
                                key={i}
                                className={`${item.chinhthuc ? 'official' : ''}`}
                                onClick={() => handleClick(item.type, item.gameid, item.providercode, item.status)}
                            >
                                <Link className="smooth">
                                    <img
                                        src={item.images_pc}
                                        alt=""
                                    />
                                    <span className="bonus">{item.title}</span>
                                    {/* <p className="bonus">
                    Phí hoàn trả tối đa{" "}
                    <span className="debate">{item.percent}</span>%
                  </p> */}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default NavbarCasino;
