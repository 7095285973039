import React from "react";
import { cas1, cas2, cas3, cas4, titleImage } from "../../img/importImg";

function HomeHotGame() {
  return (
    <div className="home-hotGame">
      <div className="container">
        <div className="head-img">
          <img src={titleImage} alt="" />
        </div>
        {/* <div
          className="flip"
          style="width: 150px; height: 200px; display: inline-block;"
        >
          <a href="/" className="rotate y">
            <img
              src="https://noithatbinhminh.com.vn/wp-content/uploads/2022/08/anh-dep-40.jpg"
              alt=""
            />

            <img
              src="https://noithatbinhminh.com.vn/wp-content/uploads/2022/08/anh-dep-40.jpg"
              alt=""
            />
          </a>
        </div> */}
        <div className="hotLinks">
          <a href="/" className="item">
            <span className="front">
              <img src={cas1} alt="" />
            </span>
            <span className="back">
              <img src={cas1} alt="" />
            </span>
          </a>
          <a href="/" className="item">
            <span className="front">
              <img src={cas2} alt="" />
            </span>
            <span className="back">
              <img src={cas2} alt="" />
            </span>
          </a>
          <a href="/" className="item">
            <span className="front">
              <img src={cas3} alt="" />
            </span>
            <span className="back">
              <img src={cas3} alt="" />
            </span>
          </a>
          <a href="/" className="item">
            <span className="front">
              <img src={cas4} alt="" />
            </span>
            <span className="back">
              <img src={cas4} alt="" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomeHotGame;
