import React, { useEffect, useState } from "react";
import {
  iconPhone,
  iconEmail,
  iconFooterWork,
  iconSecurity,
  iconService,
  pays,
} from "../../../img/importImg";
import withdrawAPI from "../../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import phoneImage from "../../../images/phone.png";

function Footer() {
  const [settings, setSettings] = useState();

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result.data[0]);

        if (result.status === true) {
          setSettings(result.data[0]);
        } else {
          return toast.error("Có lỗi xảy ra!", { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error(error, { theme: "light" });
    }
  }, []);

  return (
    <footer>
      <div className="ft-top">
        <div className="container d-flex">
          <div className="d-flex item-left">
            <div className="d-flex flex-column">
              <div className="item" style={{ fontSize: "14px" }}>
                Về chúng tôi
              </div>
              <Link className="item" to="/about">
                Về chúng tôi
              </Link>
              <Link className="item" to="/promotion">
                Ưu đãi gần đây
              </Link>
              <Link className="item" to="/support">
                Liên hệ chúng tôi
              </Link>
            </div>
            <div className="d-flex flex-column">
              <div className="item" style={{ fontSize: "14px" }}>
                VẤN ĐỀ THƯỜNG GẶP
              </div>
              <Link className="item" to="/about">
                Hướng dẫn nạp tiền
              </Link>
              <Link className="item" to="/about">
                Hướng dẫn rút tiền
              </Link>
              <Link className="item" to="/about">
                Vấn đề thường gặp
              </Link>
            </div>
            <div className="d-flex flex-column">
              <div className="item" style={{ fontSize: "14px" }}>
                Cá cược xổ số
              </div>
              <Link className="item" to="/xo-so/1">
                Xổ số VIP
              </Link>
              <Link className="item">Miền Nam</Link>
              <Link className="item">Miền Trung</Link>
              <Link className="item">Tải APP</Link>
            </div>
            <div className="d-flex flex-column">
              <div className="item" style={{ fontSize: "14px" }}>
                HỢP TÁC ĐẠI LÝ
              </div>
              <Link className="item">Chi tiết đại lý</Link>
              <Link className="item">Chính sách đại lý</Link>
              <Link className="item">Đăng ký đại lý</Link>
              <Link className="item">Đăng nhập đại lý</Link>
            </div>
          </div>

          <div className="item-right d-flex">
            <div style={{ marginBottom: "55px" }}>
              <div className="item" style={{ fontSize: "14px" }}>
                Liên hệ chúng tôi:
              </div>
              <div className="footer_shortcut-box_item">
                <div className="footer_shortcut-box_service">
                  <div className="footer_shortcut-box_service-content">
                    <span className="footer_shortcut-box_service-icon"></span>
                    <p className="footer_shortcut-box_service-text">7x24h</p>
                  </div>
                  <div className="footer_shortcut-box_service-content2">
                    <span className="footer_shortcut-box_service-text2">
                      Tổng đài <br /> trực tuyến
                    </span>
                  </div>
                </div>
              </div>
              <div className="">
                Đường dây nóng:{" "}
                {settings && <span>{settings.phone_number}</span>}
              </div>
              <div className="item">Hộp thư: {settings && settings.email}</div>
            </div>
            <div>
              <img src={phoneImage} alt="" />
            </div>
          </div>
          {/* <ul>
            <li>
              <a href="/">
                <img src={iconPhone} alt="" />
                <p>
                  Hotline: {settings && <span>{settings.phone_number}</span>}
                </p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconEmail} alt="" />
                <p>{settings && settings.email}</p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconFooterWork} alt="" />
                <p>ĐẠI LÝ</p>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={iconSecurity} alt="" />
                <p>Hướng dẫn chống hack DNS</p>
              </a>
            </li>
            <li>
              <Link to="/support">
                <img src={iconService} alt="" />
                <p>Chăm sóc khách hàng</p>
              </Link>
            </li>
          </ul> */}
        </div>
      </div>

      <div className="ft-center">
        <div className="container">
          <img src={pays} alt="" />
        </div>
      </div>

      <div className="ft-bottom">
        <div className="container">
          <div className="copyright">
            COPYRIGHT © {settings && settings.name_page} ALL RIGHTS RESERVED
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </footer>
  );
}

export default Footer;
