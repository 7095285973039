import React from "react";

const zerofill = (num) => (num < 10 && num >= 0 ? `0${num}` : num);

const SvgCircle = (props) => {
  const { className, done, max } = props;
  const radius = 25;
  const strokeWidth = 4;
  const size = (radius + strokeWidth) * 2;
  const length = Math.ceil(2 * radius * Math.PI);
  const remainingLength =
    length - Math.ceil(2 * radius * Math.PI) * (done / max);
  const isRed = done < 6;

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle
          className={`circle ${isRed ? "circle--red" : ""}`}
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke={isRed ? "red" : "#36e957"}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          className="circle--bg"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke="rgba(0, 0, 0, .1)"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </svg>
  );
};

function CountTime(props) {
  const { time } = props;
  const isRed = time < 6;

  return (
    <div className="clock">
      <div className="clock__display">
        <SvgCircle max={60} done={time} />
        <div
          className={`clock__text clock__text--seconds ${
            isRed ? "clock__text--red" : ""
          }`}
        >
          <span className="clock__amount">{zerofill(time)}</span>
        </div>
      </div>
    </div>
  );
}

export default CountTime;
