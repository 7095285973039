import {
    cama,
    doth,
    tphc,
    bali,
    vuta,
    cath,
    sotr,
    dona,
    angi,
    bith,
    tani,
    bidu,
    vilo,
    trvi,
    biph,
    hagi,
    tigi,
    kigi,
    dalat,
    //     csnlive1,
    //     csnlive2,
    //     csnlive3,
    //     csnlive4,
    //     csnlive5,
    //     csnlive6,
    //     csnlive7,
    //     csnlive8,
    //     csnlive9,
    //     csnlive10,
    //     csnlive11,
    //     gs1,
    //     gs2,
    //     gs3,
    //     gs4,
    //     gs5,
    //     gs6,
    //     gs7,
    //     gs8,
    //     gs9,
    //     gs10,
    //     gs11,
    //     gs12,
    //     bc1,
    //     bc2,
    //     bc3,
    //     bc4,
    //     tt1,
    //     tt2,
    //     tt3,
    //     tt4,
    //     tt5,
    //     tt6,
    //     tt7,
    //     tt8,
    //     tt9,
    //     gb1,
    //     gb2,
    //     gb3,
    //     gb4,
    //     dg1,
} from '../../../img/importImg';

// const dataCasino = [
//     {
//         title: 'ON LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive1,
//         chinhthuc: true,
//     },
//     {
//         title: 'AE LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive2,
//         chinhthuc: false,
//     },
//     {
//         title: 'WM LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive3,
//         chinhthuc: false,
//     },
//     {
//         title: 'DG LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive4,
//         chinhthuc: false,
//     },
//     {
//         title: 'AG LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive5,
//         chinhthuc: false,
//     },
//     {
//         title: 'SBO LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive6,
//         chinhthuc: false,
//     },
//     {
//         title: 'EBET LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive7,
//         chinhthuc: false,
//     },
//     {
//         title: 'PP LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive8,
//         chinhthuc: false,
//     },
//     {
//         title: 'BBIN LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive9,
//         chinhthuc: false,
//     },
//     {
//         title: 'MG LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive10,
//         chinhthuc: false,
//     },
//     {
//         title: 'PT LIVE CASINO',
//         phihoantra: 1.5,
//         logo: '/',
//         image: csnlive11,
//         chinhthuc: false,
//     },
// ];

// const dataGameSlot = [
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs1,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs2,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs3,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs4,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs5,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs6,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs7,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs8,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs9,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs10,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs11,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: gs12,
//         chinhthuc: false,
//     },
// ];

// const dataBanCa = [
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: bc1,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: bc2,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: bc3,
//         chinhthuc: false,
//     },
//     {
//         phihoantra: 1.5,
//         logo: '/',
//         image: bc4,
//         chinhthuc: false,
//     },
// ];

// const dataTheThao = [
//     {
//         title: 'SABAH',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt1,
//         chinhthuc: true,
//     },
//     {
//         title: 'CMD SPORT',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt2,
//         chinhthuc: false,
//     },
//     {
//         title: 'CROWN SPORT',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt3,
//         chinhthuc: false,
//     },
//     {
//         title: 'AG SPORT',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt4,
//         chinhthuc: false,
//     },
//     {
//         title: 'SBO Virtual SPORTS',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt5,
//         chinhthuc: false,
//     },
//     {
//         title: 'SBO SPORT',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt6,
//         chinhthuc: false,
//     },
//     {
//         title: 'BBIN SPORTS',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt7,
//         chinhthuc: false,
//     },
//     {
//         title: 'BBIN NEW SPORTS',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt8,
//         chinhthuc: false,
//     },
//     {
//         title: 'UG SPORTS',
//         phihoantra: 1.5,
//         logo: '/',
//         image: tt9,
//         chinhthuc: false,
//     },
// ];

// const dataGameBai = [
//     {
//         title: 'ON GAMING',
//         phihoantra: 1.5,
//         logo: '/',
//         image: gb1,
//         chinhthuc: true,
//     },
//     {
//         title: 'V8 POKER GAMING',
//         phihoantra: 1.5,
//         logo: '/',
//         image: gb2,
//         chinhthuc: false,
//     },
//     {
//         title: 'KAI YUAN GAMING',
//         phihoantra: 1.5,
//         logo: '/',
//         image: gb3,
//         chinhthuc: false,
//     },
//     {
//         title: 'LEYOU GAMING',
//         phihoantra: 1.5,
//         logo: '/',
//         image: gb4,
//         chinhthuc: false,
//     },
// ];

// const dataDaGa = [
//     {
//         title: 'DIGMAAN',
//         phihoantra: 1.5,
//         logo: '/',
//         image: dg1,
//         chinhthuc: false,
//     },
// ];

export const navbar = {
    mienbac: ['Miền Bắc'],
    trochoi: ['Tài Xỉu'],
    sieutoc: [
        { title: 'Siêu Tốc 1 Phút', link: '/xo-so/1' },
        { title: 'Siêu Tốc 3 Phút', link: '/xo-so/3' },
        { title: 'Siêu Tốc 5 Phút', link: '/xo-so/5' },
        { title: 'Siêu Tốc 10 Phút', link: '/xo-so/10' },
        { title: 'Siêu Tốc 15 Phút', link: '/xo-so/15' },
        { title: 'Siêu Tốc 20 Phút', link: '/xo-so/20' },
        { title: 'Siêu Tốc 30 Phút', link: '/xo-so/30' },
    ],
    // mega645: ["Mega 6/45 1 Giây", "Mega 6/45 1 Phút"],
    // xosoVIP: ["Hà Nội VIP", "Hồ Chí Minh VIP"],
    miennam: {
        1: [
            { title: 'Cà Mau', link: '/xo-so/xsmn-xscm' },
            { title: 'Đồng tháp', link: '/xo-so/xsmn-xsdt' },
            { title: 'TP. HCM', link: '/xo-so/xsmn-xshcm-xstp' },
        ],
        2: [
            { title: 'Bạc Liêu', link: '/xo-so/xsmn-xsbl' },
            { title: 'Vũng Tàu', link: '/xo-so/xsmn-xsvt' },
        ],
        3: [
            { title: 'Cần Thơ', link: '/xo-so/xsmn-xsct' },
            { title: 'Sóc Trăng', link: '/xo-so/xsmn-xsst' },
            { title: 'Đồng Nai', link: '/xo-so/xsmn-xsdn' },
        ],
        4: [
            { title: 'An Giang', link: '/xo-so/xsmn-xsag' },
            { title: 'Bình Thuận', link: '/xo-so/xsmn-xsbth' },
            { title: 'Tây Ninh', link: '/xo-so/xsmn-xstn' },
        ],
        5: [
            { title: 'Bình Dương', link: '/xo-so/xsmn-xsbd' },
            { title: 'Vĩnh Long', link: '/xo-so/xsmn-xsvl' },
            { title: 'Trà Vinh', link: '/xo-so/xsmn-xstv' },
        ],
        6: [
            { title: 'Bình Phước', link: '/xo-so/xsmn-xsbp' },
            { title: 'Hậu Giang', link: '/xo-so/xsmn-xshg' },
            { title: 'TP. HCM', link: '/xo-so/xsmn-xshcm-xstp' },
        ],
        0: [
            { title: 'Tiền Giang', link: '/xo-so/xsmn-xstg' },
            { title: 'Kiên Giang', link: '/xo-so/xsmn-xskg' },
            { title: 'Đà Lạt - Lâm Đồng', link: '/xo-so/xsmn-xsld-xsdl' },
        ],
        8: [
            { title: 'Cà Mau', link: '/xo-so/xsmn-xscm', img: cama },
            { title: 'Đồng tháp', link: '/xo-so/xsmn-xsdt', img: doth },
            { title: 'TP. HCM', link: '/xo-so/xsmn-xshcm-xstp', img: tphc },
            { title: 'Bạc Liêu', link: '/xo-so/xsmn-xsbl', img: bali },
            { title: 'Vũng Tàu', link: '/xo-so/xsmn-xsvt', img: vuta },
            { title: 'Cần Thơ', link: '/xo-so/xsmn-xsct', img: cath },
            { title: 'Sóc Trăng', link: '/xo-so/xsmn-xsst', img: sotr },
            { title: 'Đồng Nai', link: '/xo-so/xsmn-xsdn', img: dona },
            { title: 'An Giang', link: '/xo-so/xsmn-xsag', img: angi },
            { title: 'Bình Thuận', link: '/xo-so/xsmn-xsbth', img: bith },
            { title: 'Tây Ninh', link: '/xo-so/xsmn-xstn', img: tani },
            { title: 'Bình Dương', link: '/xo-so/xsmn-xsbd', img: bidu },
            { title: 'Vĩnh Long', link: '/xo-so/xsmn-xsvl', img: vilo },
            { title: 'Trà Vinh', link: '/xo-so/xsmn-xstv', img: trvi },
            { title: 'Bình Phước', link: '/xo-so/xsmn-xsbp', img: biph },
            { title: 'Hậu Giang', link: '/xo-so/xsmn-xshg', img: hagi },
            { title: 'Tiền Giang', link: '/xo-so/xsmn-xstg', img: tigi },
            { title: 'Kiên Giang', link: '/xo-so/xsmn-xskg', img: kigi },
            { title: 'Đà Lạt - Lâm Đồng', link: '/xo-so/xsmn-xsld-xsdl', img: dalat },
        ],
    },
    mientrung: {
        //t2
        1: [
            { title: 'Thừa T. Huế', link: '/xo-so/xsmt-xstth' },
            { title: 'Phú yên', link: '/xo-so/xsmt-xspy' },
        ],
        //t3
        2: [
            { title: 'Quảng Nam', link: '/xo-so/xsmt-xsqnm-xsqna' },
            { title: 'Đắc Lắk', link: '/xo-so/xsmt-xsdlk' },
        ],
        //t4
        3: [
            { title: 'Đà Nẵng', link: '/xo-so/xsmt-xsdng-xsdna' },
            { title: 'Khánh Hoà', link: '/xo-so/xsmt-xskh' },
        ],
        //t5
        4: [
            { title: 'Quảng Trị', link: '/xo-so/xsmt-xsqt' },
            { title: 'Quảng Bình', link: '/xo-so/xsmt-xsqb' },
            { title: 'Bình Định', link: '/xo-so/xsmt-xsbdi' },
        ],
        //t6
        5: [
            { title: 'Ninh Thuận', link: '/xo-so/xsmt-xsnt' },
            { title: 'Gia Lai', link: '/xo-so/xsmt-xsgl' },
        ],
        //t7
        6: [
            { title: 'Đà Nẵng', link: '/xo-so/xsmt-xsdng-xsdna' },
            { title: 'Quảng Ngãi', link: '/xo-so/xsmt-xsqng' },
            { title: 'Đắk Nông', link: '/xo-so/xsmt-xsdno' },
        ],
        //cn
        0: [
            { title: 'Thừa T. Huế', link: '/xo-so/xsmt-xstth' },
            { title: 'Kon Tum', link: '/xo-so/xsmt-xskt' },
            { title: 'Khánh Hoà', link: '/xo-so/xsmt-xskh' },
        ],
        8: [
            { title: 'Thừa T. Huế', link: '/xo-so/xsmt-xstth' },
            { title: 'Phú yên', link: '/xo-so/xsmt-xspy' },
            { title: 'Quảng Nam', link: '/xo-so/xsmt-xsqnm-xsqna' },
            { title: 'Đắc Lắk', link: '/xo-so/xsmt-xsdlk' },
            { title: 'Đà Nẵng', link: '/xo-so/xsmt-xsdng-xsdna' },
            { title: 'Khánh Hoà', link: '/xo-so/xsmt-xskh' },
            { title: 'Quảng Trị', link: '/xo-so/xsmt-xsqt' },
            { title: 'Quảng Bình', link: '/xo-so/xsmt-xsqb' },
            { title: 'Bình Định', link: '/xo-so/xsmt-xsbdi' },
            { title: 'Ninh Thuận', link: '/xo-so/xsmt-xsnt' },
            { title: 'Gia Lai', link: '/xo-so/xsmt-xsgl' },
            { title: 'Quảng Ngãi', link: '/xo-so/xsmt-xsqng' },
            { title: 'Đắk Nông', link: '/xo-so/xsmt-xsdno' },
            { title: 'Kon Tum', link: '/xo-so/xsmt-xskt' },
        ],
    },
    // thailan: ["1 Phút Thái Lan", "2 Phút Thái Lan", "3 Phút Thái Lan"],
};

// export { dataCasino, dataGameSlot, dataBanCa, dataTheThao, dataGameBai, dataDaGa };
