import React from "react";
import Header from "../Layout/Header/Header";
import NewsRun from "../NewsRun/NewsRun";
import BoxRight from "../BoxFixed/BoxRight";
import Footer from "../Layout/Footer/Footer";
import HotGameBubble from "../HotGameBubble/HotGameBubble";

function DefaultLayout({ children }) {
  return (
    <div>
      <Header />
      <NewsRun />
      <BoxRight />
      <div>{children}</div>
      <HotGameBubble />
      <Footer />
    </div>
  );
}

export default DefaultLayout;
