import React, { useEffect, useRef, useState } from "react";
import "./game.scss";
import {
  chipvang5,
  cuoc,
  imgstartbet,
  imgstopbet,
  quytac,
  sanh,
  setuptaixiu,
  xoaytron,
} from "../../img/importImg";
import { tonghopOld, ketQuaDice, infoCuoc, arrChip } from "../../dummy";
import CountTime from "./CountTime";
import { webSocketUrl } from "../../dummy";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import accountAPI from "../../API/account";
import HistoryUser from "./HistoryUser";
import SetUpChip from "./SetUpChip/SetUpChip";
import Bell from "../../components/Bell/Bell";
import CountUp from "react-countup";
import { getCurrentDate, VND } from "../../utils";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function Game() {
  const auth = sessionStorage.getItem("auth");
  const [isModalHistoryUser, setIsModalHistoryUser] = useState(false);
  const [soLo, setSoLo] = useState(0);
  const [tongTienCuoc, setTongTienCuoc] = useState(0);
  const [chip, setChip] = useState(
    localStorage.getItem("listChip")
      ? JSON.parse(localStorage.getItem("listChip"))[0]
      : arrChip[0]
  );
  const [tonghop, setTongHop] = useState(tonghopOld);
  const [socketUrl, setSocketUrl] = useState(webSocketUrl);
  const { sendMessage, lastMessage, readyState, sendJsonMessage } =
    useWebSocket(socketUrl);
  const [ketQua, setKetQua] = useState(ketQuaDice.slice(0, 3));
  const [infoPhien, setInfoPhien] = useState({
    hu: 0,
    md5: "",
    phien: 0,
    time: 60,
  });
  const [isCallApi, setIsCallApi] = useState(false);
  const [cuaDat, setCuaDat] = useState();
  const [historySicbo, setHistorySicbo] = useState();
  const [historyUser, setHistoryUser] = useState();
  const [soTrang, setSoTrang] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [messageNoticeSuccess, setMessageNoticeSuccess] = useState("");
  const [listChip, setListChip] = useState(
    localStorage.getItem("listChip")
      ? JSON.parse(localStorage.getItem("listChip"))
      : arrChip
  ); //localStorage.getItem("listChip") ? JSON.parse(localStorage.getItem("listChip")) :
  const [isSetUpChip, setIsSetUpChip] = useState(false);
  const [money, setMoney] = useState(0);
  const [moneyNew, setMoneyNew] = useState(0);
  const [isStopBet, setIsStopBet] = useState(false);
  const [isStartBet, setIsStartBet] = useState(false);
  const [isDifferentMoney, setIsDifferent] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [isOdd, setIsOdd] = useState(false);
  const [isEven, setIsEven] = useState(false);
  const [isLessThan11, setIsLessThan11] = useState(false);
  const [isMoreThan11, setIsMoreThan11] = useState(false);
  const [isHiddenTime, setIsHiddenTime] = useState(false);
  const [modalOne, setModalOne] = useState(false);

  const toggleOne = () => setModalOne(!modalOne);

  useEffect(() => {
    if (infoPhien.time === 6 || infoPhien.time === 5) {
      setIsStopBet(true);
    } else {
      setIsStopBet(false);
    }

    if (infoPhien.time === 46 || infoPhien.time === 45) {
      setIsStartBet(true);
    } else {
      setIsStartBet(false);
    }

    if (infoPhien.time > 46) {
      setIsHiddenTime(true);
    } else {
      setIsHiddenTime(false);
    }
  }, [infoPhien.time]);

  useEffect(() => {
    const stopBet = document.querySelector(".stop-bet");

    if (stopBet) {
      stopBet.classList.remove("fade-in");
      stopBet.classList.remove("fade-out");

      if (isStopBet) {
        stopBet.classList.add("fade-in");
      } else {
        stopBet.classList.add("fade-out");
      }
    }
  }, [isStopBet, isStartBet]);

  useEffect(() => {
    if (!auth) {
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);

      toast.error(`Vui lòng đăng nhập để trải nghiệm game!`, {
        theme: "light",
      });

      return;
    }
  }, []);

  const onStartDifferent = () => {
    setIsDifferent(true);

    setTimeout(() => {
      setIsDifferent(false);
    }, 2000);
  };

  const callbackSetupChip = () => {
    setIsSetUpChip(false);
  };

  const callbackListChip = (data) => {
    setListChip(data);
    setIsSetUpChip(false);
    localStorage.setItem("listChip", JSON.stringify(data));
  };

  const handleGetTiLe = () => {
    sendJsonMessage({
      taixiu: { getTiLe: true },
    });
  };

  const handleHistory = () => {
    sendJsonMessage({
      taixiu: { getphien: true },
    });
  };

  const handleGetHistoryByUser = () => {
    sendJsonMessage({
      taixiu: {
        history: {
          limit: 5,
          trang: soTrang,
          totalPage: totalPage,
        },
      },
    });
  };

  const callbackIsModal = () => {
    setIsModalHistoryUser(false);
  };

  const callbackFunctionSoTrang = (childData) => {
    setSoTrang(childData);
  };

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const result = await accountAPI.getUserInfo();

        if (result?.status === false) {
          return toast.error(result.msg, { theme: "light" });
        }

        setMoney(moneyNew);
        setMoneyNew(result.money);
      };

      fetchGetUserInfo();
    } catch (error) {
      return toast.error(error, { theme: "light" });
    }
  }, [isCallApi]);

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      const taixiuData = data.taixiu || {};
      const noticeData = data.notice || {};

      if (noticeData.msg === "Đăng nhập thành công") {
        setMessageNoticeSuccess(noticeData.msg);
      }

      if (noticeData.msg === "Đặt cược thành công!") {
        toast.success("Đặt cược thành công!", { theme: "light" });
        handleReset();
        setIsCallApi(!isCallApi);
        handleGetHistoryByUser();
      }

      if (taixiuData.info) {
        setInfoPhien(taixiuData.info);
      }

      if (taixiuData.tile) {
        const tiLeData = taixiuData.tile[0];

        for (let i = 0; i < tonghop.length; i++) {
          const key = tonghop[i].value;
          if (tiLeData.hasOwnProperty(key)) {
            tonghop[i].tiLe = tiLeData[key];
          }
        }

        setTongHop(tonghop);
      }

      if (taixiuData.getphien) {
        if (taixiuData.getphien.status === true) {
          const history = taixiuData.getphien.data.map((item) => {
            const result = ketQuaDice
              .flatMap((i) => {
                const count = item.ketqua
                  .split(",")
                  .filter((val) => val === i.value).length;
                return Array(count).fill(i);
              })
              .slice(0, 3);

            return { ...item, ketqua: result };
          });
          setKetQua(history[0].ketqua);
          setHistorySicbo(history);
        }
      }

      if (taixiuData.history) {
        if (taixiuData.history.status === true) {
          const historyUserNew = taixiuData.history.data.map((item) => {
            return { ...item, cuaDat: infoCuoc[item.cuadat] };
          });

          setHistoryUser(historyUserNew);
        }
      }

      if (taixiuData.finish) {
        if (taixiuData.finish.ketqua) {
          const result = ketQuaDice
            .flatMap((item) => {
              const count = taixiuData.finish.ketqua.filter(
                (val) => val === item.value
              ).length;
              return Array(count).fill(item);
            })
            .slice(0, 3);

          setIsEven(false);
          setIsOdd(false);
          setIsLessThan11(false);
          setIsMoreThan11(false);
          setTotalSum(0);

          const numbers = taixiuData.finish.ketqua.map((number) =>
            parseInt(number)
          );
          const sum = numbers.reduce((acc, curr) => acc + curr, 0);

          setKetQua(result);

          setTimeout(() => {
            setIsCallApi(!isCallApi);
            handleHistory();
            handleGetHistoryByUser();
            setTotalSum(sum);

            if (sum % 2 !== 0) {
              setIsOdd(true);
            } else setIsEven(true);

            if (sum < 11) {
              setIsLessThan11(true);
            } else setIsMoreThan11(true);
          }, 13000);
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const handleLogin = () => {
      sendJsonMessage({
        auth: { token: auth },
      });
    };

    handleLogin();
  }, []);

  useEffect(() => {
    if (messageNoticeSuccess) {
      handleGetTiLe();
      handleHistory();
      handleGetHistoryByUser();
    }
  }, [messageNoticeSuccess]);

  useEffect(() => {
    if (messageNoticeSuccess) {
      handleGetHistoryByUser();
    }
  }, [messageNoticeSuccess, soTrang]);

  function handleClick(content) {
    if (cuaDat) {
      if (cuaDat.content !== content) return;
    }

    const tonghopnew = tonghop.map((item) => {
      if (item.content === content) {
        setCuaDat(item);
        if (!chip.isCustom) {
          let tienDatCuocNew = item.tienDatCuoc + chip.value;
          return { ...item, tienDatCuoc: tienDatCuocNew };
        } else {
          let tienDatCuocNew = item.tienDatCuoc + Number(chip.value) * 1000000;
          return { ...item, tienDatCuoc: tienDatCuocNew };
        }
      }
      return item;
    });

    const [tongHopCoTienDatCuoc, tongTienDatCuoc] = tonghopnew.reduce(
      (accumulator, current) => {
        if (current.tienDatCuoc > 0) {
          accumulator[0]++;
          accumulator[1] += current.tienDatCuoc;
        }
        return accumulator;
      },
      [0, 0]
    );
    setSoLo(tongHopCoTienDatCuoc);
    setTongTienCuoc(tongTienDatCuoc);
    setTongHop(tonghopnew);
  }

  const handleReset = () => {
    setSoLo(0);
    setTongTienCuoc(0);
    setTongHop(tonghopOld);
    setCuaDat();
  };

  const handleDatCuoc = () => {
    if (infoPhien.time < 6) {
      return toast.error(`Dưới 5s không thể đặt cược!`, {
        theme: "light",
      });
    }

    if (tongTienCuoc > moneyNew) {
      return toast.error(
        `Số dư không đủ, vui lòng nạp thêm tiền để đặt cược!`,
        {
          theme: "light",
        }
      );
    }

    if (soLo > 1) {
      return toast.error(`Cược không hợp lệ!`, {
        theme: "light",
      });
    } else if (soLo === 0) {
      return toast.error(`Vui lòng chọn cửa đặt!`, {
        theme: "light",
      });
    }

    let cuadat;

    tonghop.forEach((item) => {
      if (item.tienDatCuoc > 0) {
        cuadat = item.value;
      }
    });

    sendJsonMessage({
      taixiu: { cuoc: { cuadat: cuadat, sotien: tongTienCuoc } },
    });
  };

  const handleClickChip = (value) => {
    listChip.forEach((item) => {
      if (item.value === value) {
        setChip(item);
      }
    });
  };

  return (
    <div className="bg-game">
      <div className="content-view">
        <div className="pc-taste_container">
          <div className="header-tai-xiu">
            <div className="taste_user_balance">
              <div className="taste_balance">
                <CountUp
                  start={money}
                  end={moneyNew}
                  duration={3}
                  separator="."
                  decimal=","
                  suffix=" đ"
                  onStart={onStartDifferent}
                >
                  {({ countUpRef }) => (
                    <span ref={countUpRef} className="total-money" />
                  )}
                </CountUp>
              </div>
            </div>

            <div className="phien-tai-xiu">
              {getCurrentDate()} - {infoPhien.phien}
            </div>
          </div>

          <Modal
            isOpen={modalOne}
            toggle={toggleOne}
            centered
            style={{ marginTop: "-200px" }}
          >
            <ModalHeader
              toggle={toggleOne}
              className="d-flex align-items-center justify-content-center"
            >
              <h2 className="text-center" style={{ fontSize: "16px" }}>
                Hướng Dẫn Chơi
              </h2>
            </ModalHeader>
            <ModalBody>
              <div style={{ fontSize: "14px" }}>
                <span>
                  <span className="text-blue">Tài, Xỉu: </span>
                  Tổng 3 số mở thưởng, tổng số có giá trị 4-10 là (Xỉu), tổng số
                  giá trị 11-17 là (Tài); Nếu 3 số giống nhau, sẽ tính là không
                  trúng thưởng (khấu trừ tiền gốc).
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Lẻ, Chẵn: </span>
                  Tổng số mở thưởng, tổng số lẻ là (Lẻ), tổng số chẵn là (Chẵn);
                  Nếu 3 số giống nhau, sẽ tính là không trúng thưởng (khấu trừ
                  tiền gốc)
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Tùy chọn 1: </span>
                  Chọn 1 điểm để đặt cược, kết quả mở thưởng chứa điểm được chọn
                  (không giới hạn thứ tự, bao gồm xổ bão), được tính trúng
                  thưởng.
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Tùy chọn 2: </span>
                  Chọn 2 điểm để đặt cược, kết quả mở thưởng chứa điểm được chọn
                  (không giới hạn thứ tự), được tính là trúng thưởng.
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Đối: </span>
                  Chọn một tổ hợp có cùng 2 điểm để đặt cược, điểm được chọn
                  được mở thưởng ít nhất 2 lần (thứ tự không giới hạn, xổ bão),
                  được tính là trúng thưởng
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Xúc xắc: </span>
                  Chọn một tổ hợp có cùng 3 điểm để đặt cược, điểm được chọn
                  được mở thưởng ít nhất 3 lần (thứ tự không giới hạn, xổ bão)
                  được tính là trúng thưởng.
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Xung quanh: </span>
                  Mở thưởng chứa 3 điểm giống nhau (bao gồm xổ bão) tính trúng
                  thưởng
                </span>
                <br />
                <br />
                <span>
                  <span className="text-blue">Tổng hòa: </span>
                  Chọn 1 giá trị hòa (tổng 3 điểm) để đặt cược, kết quả mở
                  thưởng chứa tổng ba số bằng giá trị hòa đã chọn, được tính
                  trúng thưởng.
                </span>
              </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
              <button className="button_confirm" onClick={toggleOne}>
                Xác Nhận
              </button>
            </ModalFooter>
          </Modal>

          <div className="content-tai-xiu row pt-5 ml-5">
            <div className="taste_content_item col-1">
              <div className="taste_menu">
                <div className="taste_menu_list pt-4">
                  <div className="taste_menu_item d-flex flex-column justify-content-center align-items-center sanh">
                    <img src={sanh} alt="" />
                    <span>Sảnh</span>
                  </div>

                  <div
                    className="taste_menu_item d-flex flex-column justify-content-center align-items-center pt-5"
                    onClick={() => setModalOne(true)}
                  >
                    <img src={quytac} alt="" />
                    <span>Quy tắc</span>
                  </div>

                  <div
                    className="taste_menu_item d-flex flex-column justify-content-center align-items-center"
                    style={{ paddingTop: "70px" }}
                    onClick={() => setIsModalHistoryUser(true)}
                  >
                    <img src={cuoc} alt="" />
                    <span>Cược</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-content row col-9">
              {tonghop.map((item, i) => {
                const isActive =
                  (item.value === "xiu" && isLessThan11) ||
                  (item.value === "tai" && isMoreThan11) ||
                  (item.value === "le" && isOdd) ||
                  (item.value === "chan" && isEven) ||
                  item.content === totalSum.toString();

                return (
                  <div
                    key={i}
                    className={`item ${isActive ? "active" : ""} ${
                      item.isHidden ? "hidden" : ""
                    }`}
                    onClick={() => handleClick(item.content)}
                  >
                    {item.isContent ? (
                      <img src={item.content} alt="" />
                    ) : (
                      <span className="item-content" key={i}>
                        {item.content}
                      </span>
                    )}

                    <span className="item-tile">{item.tiLe}</span>

                    {tonghop[i].tienDatCuoc > 0 && (
                      <img src={chipvang5} alt="" className="gold-chip show" />
                    )}

                    <span
                      className={`bet-money ${
                        tonghop[i].tienDatCuoc === 0 ? "none" : ""
                      }`}
                    >
                      {tonghop[i].tienDatCuoc.toLocaleString()}
                    </span>
                  </div>
                );
              })}
            </div>

            {!isHiddenTime && <CountTime time={infoPhien.time} />}

            <div className="bet-result">
              <div className="phien-tai-xiu">
                {getCurrentDate()} - {infoPhien.phien - 1}
              </div>
              <div className="dice-bet__result">
                {(infoPhien.time <= 60 && infoPhien.time >= 51) ||
                infoPhien.time === 0 ? (
                  <>
                    <div className="item item1"></div>
                    <div className="item item1"></div>
                    <div className="item item1"></div>
                  </>
                ) : (
                  <>
                    <img src={ketQua[0].img} alt="" />
                    <img src={ketQua[1].img} alt="" />
                    <img src={ketQua[2].img} alt="" />
                  </>
                )}
              </div>
            </div>

            <div className="container-chuong">
              <Bell ketQua={ketQua} time={infoPhien.time} />
            </div>

            {/* <Bell ketQua={ketQua} time={infoPhien.time} /> */}

            <div className="col-2 justify-content-center d-flex">
              <div className="taste_history">
                <div className="game-history" style={{ width: "162px" }}>
                  <div className="is_taste_game_title">Lịch sử mở thưởng</div>
                  <div className="history_sicbo">
                    {historySicbo &&
                      historySicbo.map((item, i) => {
                        return (
                          <div key={i} className="item">
                            <span>{item.id}</span>
                            <div className="dice">
                              <img
                                src={item.ketqua[0].img}
                                alt=""
                                className=""
                              />
                              <img
                                src={item.ketqua[1].img}
                                alt=""
                                className=""
                              />
                              <img
                                src={item.ketqua[2].img}
                                alt=""
                                className=""
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-tai-xiu d-flex flex-column justify-content-center align-items-center">
            <div className="chip-tai-xiu d-flex">
              {/* {arrChip.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="chip-container"
                    onClick={() => handleClickChip(item.value)}
                  >
                    <img src={item.img} alt="" className="chip" />
                    <img
                      src={xoaytron}
                      alt=""
                      className={`rotate-image ${
                        chip.value !== item.value ? "none" : ""
                      }`}
                    />
                  </div>
                );
              })} */}
              {listChip &&
                listChip.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="chip-container"
                      onClick={() => handleClickChip(item.value)}
                    >
                      {!item.isCustom ? (
                        <img src={item.img} alt="" className="chip" />
                      ) : (
                        <div className="custom-chip__isValue">
                          <span>{item.value}</span>
                        </div>
                      )}

                      <img
                        src={xoaytron}
                        alt=""
                        className={`rotate-image ${
                          chip.value !== item.value ? "none" : ""
                        }`}
                      />
                    </div>
                  );
                })}
              <div className="chip-container">
                <img
                  src={setuptaixiu}
                  alt=""
                  style={{ width: "57px", height: "62px", cursor: "pointer" }}
                  onClick={() => setIsSetUpChip(true)}
                />
              </div>
            </div>

            <div className="footer-dat-cuoc d-flex align-items-center justify-content-around">
              <div className="cai-lai footer-button" onClick={handleReset}>
                <span>Cài lại</span>
              </div>

              <div className="so-tien-dat-cuoc d-flex flex-column justify-content-center align-items-center">
                <div className="so-lo">
                  <span>
                    Đã chọn <span className="text-blue">{soLo}</span> Lô
                  </span>
                </div>

                <div className="tong-tien-dat-cuoc">
                  <div className="content-tien-dat-cuoc">
                    <span className="text-blue">Số tiền</span>
                    <div className="bet_taste_line"></div>
                    <span>{VND.format(tongTienCuoc)}</span>
                  </div>
                </div>
              </div>

              <div className="dat-cuoc footer-button" onClick={handleDatCuoc}>
                <span>Đặt cược</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalHistoryUser && (
        <HistoryUser
          isModalHistoryUser={isModalHistoryUser}
          historyUser={historyUser}
          callbackIsModal={callbackIsModal}
          soTrang={soTrang}
          totalPage={totalPage}
          callbackFunctionSoTrang={callbackFunctionSoTrang}
        />
      )}

      {isSetUpChip && (
        <SetUpChip
          callbackSetupChip={callbackSetupChip}
          callbackListChip={callbackListChip}
        />
      )}

      {isStopBet && (
        <div className="background-opacity stop-bet">
          <img
            src={imgstopbet}
            alt=""
            style={{ width: "366px", height: "122px" }}
          />
        </div>
      )}

      {isStartBet && (
        <div className="background-opacity stop-bet">
          <img
            src={imgstartbet}
            alt=""
            style={{ width: "366px", height: "122px" }}
          />
        </div>
      )}

      {isDifferentMoney && (
        <div className="background-opacity">
          <div className="background-money">
            {moneyNew - money >= 0 ? (
              <span style={{ color: "#36e957" }}>{`+ ${VND.format(
                moneyNew - money
              )}`}</span>
            ) : (
              <span style={{ color: "#f70b12" }}>{`- ${VND.format(
                money - moneyNew
              )}`}</span>
            )}
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Game;
