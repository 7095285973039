import React, { useState, useEffect } from "react";
import "./About.scss";
import {
  iconAbout,
  iconAphone,
  iconGetMoney,
  iconMoney,
  iconRight,
  iconQA,
  slogan,
  link,
} from "../../img/importImg";
import withdrawAPI from "../../API/withdrawAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../dummy";
import { Link } from "react-router-dom";

function About() {
  const [about, setAbout] = useState(true);
  const [aphone, setAphone] = useState(false);
  const [money, setMoney] = useState(false);
  const [getMoney, setGetMoney] = useState(false);
  const [qa, setQA] = useState(false);
  const [settings, setSettings] = useState();
  const [telegramLink, setTelegramLink] = useState();

  useEffect(() => {
    try {
      const fetchGetSetting = async () => {
        const result = await withdrawAPI.getSetting();
        // console.log(result.data[0]);

        if (result.status === true) {
          const telegram = result.data[0].link_telegram.split(";");
          setTelegramLink(telegram);

          setSettings(result.data[0]);
        } else {
          return toast.error("Có lỗi xảy ra!", { theme: "light" });
        }
      };

      fetchGetSetting();
    } catch (error) {
      toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);

  const handleElementClick = (elementName) => {
    setAbout(elementName === "about");
    setAphone(elementName === "aphone");
    setMoney(elementName === "money");
    setGetMoney(elementName === "getMoney");
    setQA(elementName === "qa");
  };

  return (
    <div>
      <main className="txt-box">
        <div className="tab wrap tab-colum">
          <ul className="tab_ctrl mb20">
            <li
              className="tab_btn"
              data-type="about"
              onClick={() => handleElementClick("about")}
            >
              <a className="style-link-123">
                <span className="icon">
                  <img src={iconAbout} alt="" />
                </span>
                <p className="uppercase">Về Chúng Tôi</p>
                <span className="more">
                  <img src={iconRight} alt="" />
                </span>
              </a>
            </li>
            <li
              className="tab_btn"
              data-type="contact"
              onClick={() => handleElementClick("aphone")}
            >
              <a className="style-link-123">
                <span className="icon">
                  <img src={iconAphone} alt="" />
                </span>
                <p className="uppercase">Liên Hệ Chúng Tôi</p>
                <span className="more">
                  <img src={iconRight} alt="" />
                </span>
              </a>
            </li>
            <li
              className="tab_btn"
              data-type="deposit"
              onClick={() => handleElementClick("money")}
            >
              <a className="style-link-123">
                <span className="icon">
                  <img src={iconMoney} alt="" />
                </span>
                <p className="uppercase">HƯỚNG DẪN NẠP TIỀN</p>
                <span className="more">
                  <img src={iconRight} alt="" />
                </span>
              </a>
            </li>
            <li
              className="tab_btn"
              data-type="withdrawal"
              onClick={() => handleElementClick("getMoney")}
            >
              <a className="style-link-123">
                <span className="icon">
                  <img src={iconGetMoney} alt="" />
                </span>
                <p className="uppercase">HƯỚNG DẪN RÚT TIỀN</p>
                <span className="more">
                  <img src={iconRight} alt="" />
                </span>
              </a>
            </li>
            <li
              className="tab_btn"
              data-type="general"
              onClick={() => handleElementClick("qa")}
            >
              <a className="style-link-123">
                <span className="icon">
                  <img src={iconQA} alt="" />
                </span>
                <p className="uppercase">Vấn ĐỀ Thường Gặp</p>
                <span className="more">
                  <img src={iconRight} alt="" />
                </span>
              </a>
            </li>
            <span className="slogan">
              <img src={slogan} alt="" />
            </span>
            <span className="web">
              {settings && (
                <img
                  src={baseUrl + settings.logo_image}
                  alt=""
                  className="w-75 h-75"
                />
              )}
            </span>
          </ul>
          <div className="tab_main">
            <ul className={about ? "tab_item" : "hide tab_item"}>
              <li>
                <p className="tit focus uppercase">Về chúng tôi</p>
                {settings && settings.name_page} được thành lập vào năm 2012 và
                chuyên điều hành các hoạt động kinh doanh trò chơi khác nhau.
                Các sản phẩm và dịch vụ
                {settings && settings.name_page} cung cấp được ủy quyền và giám
                sát bởi Công ty Cổ phần Giải trí & Nghỉ dưỡng First Cagayan
                (www.firstcagayan.com) tại Đặc khu Kinh tế Sông Cagayan của
                Philippines.{settings && settings.name_page} luôn hoạt động 24/7
                tất cả các ngày trong năm,luôn cung cấp tất cả các sản phẩm,dịch
                vụ mới & chất lượng tốt nhất. Với mong muốn đem lại cho khách
                hàng,những trải nghiệm giải trí trực tuyến tuyệt vời nhất cho
                tất cả các thành viên tham gia tại{" "}
                {settings && settings.name_page}.
                <br /> {settings && settings.name_page} đã hợp tác kỹ thuật
                chuyên sâu với CX, AG, BG, OG, CQ9, IBO, v.v. để cùng xây dựng
                một nền tảng game chất lượng cao.{" "}
                {settings && settings.name_page} sở hữu một công ty trò chơi
                được đăng ký hợp pháp tại Philippines. Tất cả các hoạt động trò
                chơi của {settings && settings.name_page} đều tuân thủ hiệp ước
                trò chơi của Chính phủ Philippines. Trong thị trường cờ bạc trực
                tuyến ngày càng cạnh tranh khốc liệt,
                {settings && settings.name_page} không ngừng tìm kiếm sự đổi mới
                và thay đổi, tìm kiếm những ý tưởng mới nhất và đề cao dịch vụ
                tốt nhất.Ngoài các sản phẩm giải trí mới lạ, thú vị{" "}
                {settings && settings.name_page} còn mang đến cho người chơi với
                dịch vụ chăm sóc khách hàng nhanh chóng, thuận lợi, chu đáo &
                chuyên nghiệp nhất.
                <br /> Là một nhà điều hành trò chơi cờ bạc trực tuyến chuyên
                nghiệp quốc tế có uy tín cao nhất trên thị trường cá cược,
                {settings && settings.name_page} tuân thủ nguyên tắc là "Thà mất
                tiền chứ không làm mất uy tín"trung thực là nền tảng của cuộc
                sống"đức tính là nền tảng của đạo đức".Cung cấp cho mọi khách
                hàng những trò chơi cá cược,an toàn và công bằng nhất & đầy đủ
                các dịch vụ. Ưu điểm chung của tất cả các trò chơi là :không tốn
                thời gian tải xuống màn hình,đơn giản và rõ ràng,chức năng hoạt
                động hoàn chỉnh, màn hình tinh tế và trang nhã,
                {settings && settings.name_page} cam kết cung cấp cho khách hàng
                những dịch vụ cũng như những trò chơi an toàn, trung thực và
                công bằng nhất.
              </li>
              <li>
                <p className="tit focus">Nguyên tắc rút tiền không giới hạn</p>
                {settings && settings.name_page} đã tuân thủ nguyên tắc của
                người chơi "rút tiền không giới hạn" để đảm bảo rằng phần lớn
                người chơi có thể tận hưởng trò chơi một cách tối đa!
              </li>
              <li>
                <p className="tit focus uppercase">Trò chơi xổ số</p>
                {settings && settings.name_page} là một trong những nhà cái cá
                cược xổ số trực tuyến lớn nhất ở việt nam, cung cấp cho bạn dịch
                vụ mua và phân tích vé số thuận tiện. Trong những năm gần đây,
                chúng tôi cam kết đầu tư phát triển đa dạng các trò chơi xổ số,
                xổ số chính thống, xổ số địa phương,… được các thành viên yêu
                thích với tỷ lệ cược và hoàn trả siêu cao, phương thức chơi đa
                dạng.
              </li>
              <li>
                <p className="tit focus uppercase">Video trực tiếp</p>
                {settings && settings.name_page} có nền tảng video trực tiếp
                hàng đầu thế giới, cho phép khách hàng có nhiều sự lựa chọn các
                sảnh game live casino được yêu thích nhất thị trường hiện
                nay.Cung cấp cho khách hàng các trò chơi như : Baccarat, Sic Bo,
                Dragon Tiger Fight, Roulette và các nội dung thú vị khác cho tất
                cả người chơi.
              </li>
              <li>
                <p className="tit focus uppercase">Trò chơi điện tử</p>
                Có nhiều sự lựa chọn cho quý khách, sau khi đăng nhập, quý khách
                có thể lựa chọn các sảnh game slot với sự đa dạng các bàn và các
                tiện ích để khách hàng có thể cảm nhận công nghệ và thử vận may
                ,Với công nghệ mới và sự sáng tạo, cũng như sự tin tưởng và ủng
                hộ của khách hàng, trong thời gian tới,{" "}
                {settings && settings.name_page} sẽ tiếp tục tuân thủ tinh thần
                "tìm kiếm sự thay đổi", làm việc không mệt mỏi để tìm kiếm đột
                phá, phát triển nhiều trò chơi chất lượng cao hơn cho người chơi
                cảm nhận niềm vui giải trí hòa mình vào thế giới trò chơi điện
                tử!
              </li>
              <li>
                <p className="tit focus uppercase">Cá cược thể thao</p>
                {settings && settings.name_page} cung cấp tỷ lệ cược và xu hướng
                thời gian thực của các sự kiện thể thao phổ biến nhất thế giới,
                bao gồm các sự kiện quốc tế như bốn giải bóng đá lớn của châu
                Âu, NBA của Hoa Kỳ và bốn giải quần vợt lớn. Bạn cũng có thể
                chọn nhiều phương thức cá cược khác nhau, chẳng hạn như tỷ lệ
                chấp và tỷ lệ, nửa trận / toàn trận, thắng một lần, v.v. và sau
                đó nắm bắt cơ hội giành tiền dựa trên dữ liệu thời gian thực chi
                tiết và mới nhất!
              </li>
              <li>
                <p className="tit focus uppercase">An toàn và bảo mật</p>
                Tất cả các giao dịch ngân hàng và thanh toán trực tuyến của
                khách hàng đều được thực hiện bởi các tổ chức tài chính quốc tế
                tuyệt đối an toàn và bảo mật (Chuẩn mã hóa SSL 128 bit)
                <br /> Mỗi khách hàng đều được cung cấp một ID và mật khẩu đăng
                nhập duy nhất để đảm bảo rằng các khoản tiền của quý khách được
                an toàn và bảo mật. Chúng được mã hóa bởi các biện pháp mã hóa
                tiên tiến nhất.
                <br /> Với đội ngũ chăm sóc trực tuyến 24/07 chúng tôi đảm bảo
                một không gian trò chơi an toàn và trung thực nhất.
                <br /> Khách hàng được giữ bí mật tuyệt đối khi đăng nhập và
                chơi trên hệ thống của {settings && settings.name_page}.{" "}
                {settings && settings.name_page} cam kết không bao giờ tiết lộ
                thông tin khách hàng cho các bên thứ 3 (bao gồm cả chính phủ).
              </li>
              <li>
                <p className="tit focus uppercase">dịch vụ khách hàng</p>
                Trong thị trường game cạnh tranh khốc liệt,
                {settings && settings.name_page} vẫn luôn là lựa chọn duy nhất
                của nhiều khách hàng. Bởi ngoài các sản phẩm giải trí mới lạ,
                thú vị {settings && settings.name_page} còn mang đến cho người
                chơi một dịch vụ chăm sóc khách hàng nhanh chóng, thuận lợi, chu
                đáo và chuyên nghiệp nhất.'
                <br /> Tương tác đa kênh : Với đa dạng kênh liên lạc, đội ngũ
                chăm sóc khách hàng luôn sẵn sàng túc trực để hỗ trợ kịp thời
                nhất.
                <br /> Luôn mang lại sự bất ngờ và ngạc nhiên cho quý khách bởi
                các chương trình khuyến mãi hấp dẫn.
              </li>
            </ul>
            {settings && (
              <ul className={aphone ? "tab_item" : "hide tab_item"}>
                <li>
                  <p className="tit focus">Liên Hệ Chúng Tôi</p>
                  Nếu bạn có bất kỳ thắc mắc nào có thể trực tiếp liên hệ với
                  chuyên viên CSKH 24/7 hoặc liên hệ với chúng tôi qua zalo,
                  email, chuyên viên CSKH sẽ phản hồi trong vòng 24 giờ.
                  <br />
                  <br />
                  <p className="focus py-4">
                    Telegram :
                    <span className="telegramAccount txt-white">
                      {telegramLink && (
                        <Link to={telegramLink[1]} className="pl-4">
                          Liên hệ ngay
                        </Link>
                      )}
                    </span>
                  </p>
                  <p className="focus py-4">
                    Hotline :
                    <span className="cfgMainPhone txt-white pl-4">
                      {settings.phone_number}
                    </span>
                  </p>
                  <p className="focus py-4">
                    Zalo:
                    <span className="zaloAccount txt-white">
                      <Link to={settings.link_zalo} className="pl-4">
                        Liên hệ ngay
                      </Link>
                    </span>
                  </p>
                  <p className="focus py-4">
                    Email:
                    <span className="cfgMainEmail txt-white pl-4">
                      {settings.email}
                    </span>
                  </p>
                  <p className="focus py-4">
                    Ý kiến phản hồi zalo :
                    <span className="cfgMainAgentQQ">
                      <Link to={settings.link_zalo} className="pl-4">
                        Liên hệ ngay
                      </Link>
                    </span>
                  </p>
                </li>
              </ul>
            )}

            <ul className={money ? "tab_item" : "hide tab_item"}>
              <li>
                <p className="tit focus uppercase">
                  LÀM THẾ NÀO ĐỂ NẠP TIỀN VÀO {settings && settings.name_page}
                </p>
                <span className="sub-tit">
                  Phương thức nạp tiền chuyển khoản
                </span>
                {`Quý khách có thể đăng nhập vào ${
                  settings && settings.name_page
                } vào mục=>nạp tiền =>kiểm tra thông tin ngân hàng của công ty ở phía dưới sau đó chọn ngân hàng điền => số tiền => nhập tên tài khoản=>gửi hoặc có thể liên hệ Chăm Sóc Khách Hàng (CSKH) để được cung cấp thông tin tài khoản ngân hàng. Quý khách có thể nạp tiền bằng nhiều hình thức khác nhau vào tài khoản đã được cung cấp như: Gửi tiền mặt tại quầy ngân hàng, gửi tiền mặt tại bưu điện, gửi tiền mặt tại quầy Viettel hay chuyển khoản tại cây ATM gần nhất, trong lúc chờ nhân viên bên ${
                  settings && settings.name_page
                } xác nhận,quý khách vui lòng kiên nhẫn đợi trong giấy lát nhân viên sẽ cập nhật lên điểm trong thời gian sớm nhất, hoặc có thể theo dõi trạng thái nạp tiền của quý khách bất cứ lúc nào , và số tiền quý khách nạp sau khi được nhân viên xác nhận sẽ tự động cập nhật vào tài khoản , nếu lệnh nạp đợi lâu quý khách vui lòng kiểm tra lệnh chuyển tiền của mình có thành không hoặc chuyển có đúng thông tin không nhé!`}
              </li>
              <li>
                <span className="sub-tit">Phương thức nạp tiền trực tuyến</span>
                <p className="tit focus">
                  Nạp tiền qua qua phương thức nạp tiền trực tuyến :
                </p>
                {
                  "Sau khi quý khách đăng nhập =>nạp tiền =>Thanh toán trực tuyến=>kiểm tra thông tin ngân hàng của công ty ở phía dưới sau đó chọn ngân hàng và điền =>số tiền => nhập tên tài khoản => gửi. Thanh toán trực tuyến chia thành 2 loại, cụ thể là thanh toán qua ví điện tử và chuyển khoản ngân hàng trực tuyến."
                }
                <br />{" "}
                {`Mỗi cách chuyển tiền có thể có một số tài khoản khác nhau, quý khách chuyển khoản lưu ý điền mã thanh toán và chỉ cần chọn bất kỳ 1 tài khoản là được.Quý khách thực hiện theo các bước trên hệ thống đã được mặc định sẵn của ${
                  settings && settings.name_page
                }.`}
                Mỗi cách chuyển tiền có thể có một số tài khoản khác nhau, quý
                khách chuyển khoản lưu ý điền mã thanh toán và chỉ cần chọn bất
                kỳ 1 tài khoản là được.Quý khách thực hiện theo các bước trên hệ
                thống đã được mặc định sẵn của {settings && settings.name_page}.
                <br /> Sau khi làm lệnh, hệ thống sẽ mất khoảng thời gian ngắn
                để xác nhận thông tin chuyển khoản của quý khách.Sau khi xác
                nhận thành công, số tiền của quý khách sẽ được cập nhật ngay vào
                tài khoản, nếu lệnh nạp đợi lâu quý khách vui lòng kiểm tra lệnh
                chuyển tiền của mình quý khách nên giữ lại hóa đơn chuyển tiền
                và liên hệ với CSKH để được hỗ trợ!
                <br /> Lưu ý:Qúy khách sau khi đăng ký tài khoản thành công ,
                vui lòng chú ý điền đúng thông tin ngân hàng phải trùng khớp với
                tên thật đã đăng ký , nếu không sẽ không được rút tiền !
              </li>
            </ul>
            <ul className={getMoney ? "tab_item" : "hide tab_item"}>
              <li>
                <p className="tit focus">
                  LÀM THẾ NÀO ĐỂ RÚT TIỀN TẠI {settings && settings.name_page}
                </p>
                <span className="sub-tit">Trợ giúp rút tiền</span>{" "}
                {settings && settings.name_page} cam kết mang đến cho quý khách
                chất lượng dịch vụ tốt nhất, rút tiền bất cứ thời gian nào trong
                ngày, không giới hạn số lần và số tiền rút. Không cần phải trả
                bất kỳ khoản phí nào.
                <br /> Phương thức rút tiền như sau:
                <br />{" "}
                {
                  "Qúy khách bấm vào => Rút tiền hệ thống sẽ xuất hiện giao diện rút tiền yêu cầu quý khách xác nhận số tiền cần rút. Sau đó hãy nhập mật khẩu rút tiền và nhấn => xác nhận giao dịch để tiếp tục thực hiện."
                }
                <br /> Nếu quý khách có thắc mắc, vui lòng liên hệ với bộ phận
                chăm sóc khách hàng trực tuyến 24/7, chúng tôi sẽ giúp bạn giải
                đáp vấn đề với tốc độ nhanh nhất.
              </li>
              <li>
                <p className="tit focus">Lưu ý khi rút tiền</p>
                Lưu ý: Sau khi đăng ký tài khoản thành công , vui lòng chú ý
                điền đúng thông tin ngân hàng phải trùng khớp với tên thật đã
                đăng ký , nếu không sẽ không được rút tiền !mỗi khách hàng chỉ
                được sử dụng 1 tài khoản ngân hàng, nếu có nhu cầu thay đổi tài
                khoản hoặc thông tin xin vui lòng liên hệ CSKH để được hỗ trợ,
                sau khi làm lệnh rút tiền quý vui lòng chờ trong giây lát,nhân
                viên {settings && settings.name_page} xét duyệt lệnh rút trong
                khoảng 3-5 phút, trong trường hợp ngân hàng bảo trì thì thời
                gian rút tiền sẽ tùy thuộc vào tiến độ bảo trì của ngân hàng.
              </li>
            </ul>
            <ul className={qa ? "tab_item" : "hide tab_item"}>
              <li>
                <p className="tit focus">
                  Có an toàn để chơi trò chơi trong{" "}
                  {settings && settings.name_page} không?
                </p>
                Hệ thống của {settings && settings.name_page} chúng tôi là hoàn
                toàn an toàn. {settings && settings.name_page}
                sẽ không bao giờ tiết lộ thông tin cá nhân của quý khách hàng
                cho bất kì doanh nghiệp nào. Ngoài ra, đối với các giao dịch,
                chúng tôi cũng yêu cầu với ngân hàng, đại lý chuyển khoản, thẻ
                tín dụng, và các đối tác của công ty luôn bí mật thông tin của
                quý khách. Tất cả các khoản tiền gửi sẽ được coi là tài khoản
                thương mại và sẽ không thực hiện chuyển cho người khác.
              </li>
              <li>
                <p className="tit focus">
                  Cá cược trực tuyến có hợp pháp không?
                </p>
                Một số quốc gia hoặc khu vực mà pháp luật địa phương nghiêm cấm
                cờ bạc. Trong trường hợp này, hãy đảm bảo tuân thủ pháp luật địa
                phương. Nếu quý khách có bất cứ yêu cầu nào, vui lòng tìm cố vấn
                pháp luật tại địa phương để hiểu rỏ hơn. Công ty không thể và
                không chịu bất cứ trách nhiệm pháp lý nào phát sinh từ bất cứ
                quý khách hàng nào vi phạm pháp luật địa phương.
              </li>
              <li>
                <p className="tit focus">
                  Có giới hạn độ tuổi đặt cược trong{" "}
                  {settings && settings.name_page} không?
                </p>
                Độ tuổi hợp pháp để cá cược phải từ 18 tuổi trở lên.
              </li>
              <li>
                <p className="tit focus">
                  Tôi có nên điền họ và tên của mình khi mở tài khoản không?
                </p>
                Vì lý do bảo mật, Phòng Tài chính sẽ xem xét việc rút tiền theo
                tên đã đăng ký, tên tài khoản thẻ ngân hàng phải trùng với tên
                đã đăng ký thì mới được thực hiện rút tiền, do đó tên bạn điền
                khi mở tài khoản phải trùng với tên tài khoản rút tiền.
              </li>
              <li>
                <p className="tit focus">
                  Tôi nên làm gì nếu tôi quên mật khẩu của mình?
                </p>
                Bạn có thể bấm vào chức năng quên mật khẩu trên trang chủ, đồng
                thời liên hệ với nhân viên chăm sóc khách hàng trực tuyến 24/24
                để được tư vấn và hỗ trợ lấy lại mật khẩu tài khoản.
              </li>
              <li>
                <p className="tit focus">
                  Tôi có thể nạp tiền vào tài khoản trò chơi của mình bằng cách
                  nào?
                </p>
                Công ty hiện cung cấp nhiều cách nạp tiền, vui lòng bấm nạp tiền
                trực tuyến để chọn một trong các cách nạp tiền, xin cảm ơn! (Lưu
                ý: Vui lòng theo dõi tài khoản nhận mới nhất hiển thị trên trang
                trước mỗi lần thanh toán. Nếu bạn gửi tiền vào tài khoản đã hết
                hạn, công ty sẽ không thể kiểm tra. Chúng tôi không chịu trách
                nhiệm. Vui lòng chú ý, xin cảm ơn!)
              </li>
              <li>
                <p className="tit focus">
                  Tại sao hạn mức không được trả về tài khoản game của tôi ngay
                  sau khi thanh toán trực tuyến thành công?
                </p>
                Điều này là do hệ thống thanh toán trực tuyến cần trải qua quy
                trình khóa bí mật và quy trình xác minh nội bộ và đây là một
                trong những quy trình bảo mật của chúng tôi, vì vậy đừng lo
                lắng, tiền sẽ không bị mất. Nếu bạn vẫn chưa nhận được thanh
                toán của mình sau hơn 30 phút thanh toán trực tuyến, vui lòng
                liên hệ với bộ phận chăm sóc khách hàng của chúng tôi để xử lý.
              </li>
              <li>
                <p className="tit focus">
                  Khi tôi thắng tiền trong trò chơi, tôi phải làm thế nào để rút
                  tiền?
                </p>
                Xin chào, vui lòng nhấp vào "Rút tiền trực tuyến" để nhập mật
                khẩu rút tiền của bạn, sau đó điền số tiền bạn muốn rút theo yêu
                cầu. Không có giới hạn trên cho số tiền rút. Sau đó bấm OK một
                lần nữa!
              </li>
              <li>
                <p className="tit focus">
                  Làm thế nào để chứng minh rằng kết quả trò chơi là chính thức
                  hay thời gian thực?
                </p>
                Bạn có thể so sánh thông qua trang web xổ số chính thức trong
                luật chơi, hoặc theo dõi video hình ảnh trò chơi, hình ảnh trong
                video đều được đồng bộ với hình ảnh chơi trong thành phố giải
                trí trực tiếp trên từng nền tảng. Chúng tôi đảm bảo rằng tất cả
                các kết quả trò chơi đều là kết quả theo thời gian thực.
              </li>
              <li>
                <p className="tit focus">
                  Tôi có thể duyệt qua trò chơi của bạn trước không?
                </p>
                Chúng tôi hoan nghênh bạn duyệt qua hệ thống trò chơi của chúng
                tôi. Bạn có thể nhấp vào "Dùng thử miễn phí" để duyệt các trò
                chơi của chúng tôi.
              </li>
              <li>
                <p className="tit focus">
                  Tôi có thể tìm các quy tắc của trò chơi ở đâu?
                </p>
                Sau khi đăng nhập, bạn có thể thấy các tùy chọn về luật chơi
                trong mỗi mục trò chơi, cho biết rõ cách chơi, luật chơi và
                phương thức thanh toán.
              </li>
              <li>
                <p className="tit focus">
                  Có yêu cầu về cấu hình hệ thống để chơi game không?
                </p>
                Xin chào, trang web chúng tôi thiết kế sẽ cung cấp thế hệ dịch
                vụ trình duyệt mới, cung cấp các tính năng tốt hơn, để bạn có
                thể tận hưởng niềm vui cá cược tốt hơn. Chúng tôi hy vọng bạn có
                thể sử dụng phiên bản trình duyệt Internet Explorer 9.0 trở lên
              </li>
              <li>
                <p className="tit focus">Tại sao hệ thống đăng xuất tôi?</p>
                Đây là một trong những chương trình bảo mật của trang web. Khi
                bạn không sử dụng trang web liên quan trong một khoảng thời gian
                nhất định, trang web liên quan sẽ không còn hoạt động và bạn
                không thể duyệt trang mới nhất. Tại đây, bạn phải đăng nhập lại;
                nếu bạn đăng nhập lại Tình hình vẫn vậy, hãy liên hệ ngay với
                chúng tôi.
              </li>
              <li>
                <p className="tit focus">
                  Tại sao tôi cần xóa bộ nhớ cache của web?
                </p>
                Nếu bạn không thể duyệt trang web mới hoặc trang mới nhất và bạn
                thấy rằng bạn đang xem cùng một trang hoặc trang cũ, điều đó có
                nghĩa là bộ nhớ của bạn đã đầy.
              </li>
            </ul>
          </div>
        </div>
      </main>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default About;
