import React, { useState, useEffect } from "react";
import accountAPI from "../../API/account";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { avatar } from "../../img/importImg";
import { baseUrl } from "../../dummy";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function InfoMy() {
  const [user, setUser] = useState();

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const result = await accountAPI.getUserInfo();
        // console.log(result);
        setUser(result);
      };

      fetchGetUserInfo();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, []);

  return (
    <div>
      <div className="bg-light d-flex py-4 px-4">
        {localStorage.getItem("link_logo") && (
          <img
            src={baseUrl + localStorage.getItem("link_logo")}
            alt=""
            className=""
            style={{ borderRadius: "10px" }}
            width={125}
            height={50}
          />
        )}

        <div className="d-flex pl-4 flex-column">
          <span>Xin chào, {user?.username}</span>
          <div className="d-flex pt-2">
            <span>
              Tài khoản: <span className="text-primary">{user?.username}</span>
            </span>
            <span className="pl-2">
              ID: <span className="text-primary">{user?.id}</span>
            </span>
            <span className="pl-2">
              Số điện thoại: <span className="text-primary">{user?.phone}</span>
            </span>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default InfoMy;
