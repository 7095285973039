import axiosClient from "./axiosClient";

const gameAPI = {
  postLaunchGame: (data) => {
    const url = `/api/user/LaunchGame`;
    return axiosClient.post(url, data);
  },

  getlistGame: () => {
    const url = `/api/user/listGame`;
    return axiosClient.get(url);
  },

  getRefundGroup: () => {
    const url = `/api/webapi/refundGroup`;
    return axiosClient.get(url);
  },

  getBetHistory: (type) => {
    const url = `/api/user/bet-history/${type}`;
    return axiosClient.get(url);
  },

  getRefundLogs: () => {
    const url = `/api/user/refundhistory`;
    return axiosClient.get(url);
  },
};

export default gameAPI;
