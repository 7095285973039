import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import pageAPI from "../../API/pageAPI";
import { baseUrl } from "../../dummy";

function Banner() {
  const [banner, setBanner] = useState();

  useEffect(() => {
    try {
      const fetchGetBanner = async () => {
        const result = await pageAPI.getBanner();
        // console.log(result);

        if (result.status === true) {
          setBanner(result.data);
        }
      };

      fetchGetBanner();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Carousel
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      dynamicHeight={true}
      emulateTouch={true}
      infiniteLoop={true}
      interval={2500}
      showIndicators={true}
      showThumbs={false}
      swipeable={true}
    >
      {banner &&
        banner.map((item, i) => {
          return (
            <div key={i}>
              <img src={`${baseUrl + item.link}`} alt="banner" />
            </div>
          );
        })}
    </Carousel>
  );
}

export default Banner;
