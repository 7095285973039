import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { publicRoutes } from "./routes";
import DefaultLayout from "./components/DefaultLayout/DefaultLayout";
import { Helmet } from "react-helmet";
import withdrawAPI from "./API/withdrawAPI";
import { baseUrl } from "./dummy";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const auth = sessionStorage.getItem("auth");

  const [settings, setSettings] = useState();

  useEffect(() => {
    try {
      const fetchGetUserInfo = async () => {
        const dataSetting = await withdrawAPI.getSetting();
        setSettings(dataSetting.data[0]);
      };
      fetchGetUserInfo();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        {settings && (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{settings.title}</title>
            <meta name="description" content={settings.description_page} />
            <link rel="icon" href={baseUrl + settings.favicon} />
          </Helmet>
        )}

        <Routes>
          {publicRoutes.map((route, index) => {
            let Layout = DefaultLayout;
            if (route.layout) {
              Layout = route.layout;
            } else if (route.layout === null) {
              Layout = Fragment;
            }
            const Page = route.component;
            if (auth) {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Page link={route.path} title={route.title} />
                    </Layout>
                  }
                  exact
                />
              );
            } else if (route.requiresAuth) {
              // Nếu không có auth và trang yêu cầu phải xác thực thì chuyển hướng về trang login
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<Navigate to="/" />}
                  exact
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Page link={route.path} title={route.title} />
                    </Layout>
                  }
                  exact
                />
              );
            }
          })}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
