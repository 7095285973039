import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import gameAPI from "../../API/gameAPI";
import ReactLoading from "react-loading";

const VND = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

function BetHistoryByGame(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [betHistory, setBetHistory] = useState([]);

  useEffect(() => {
    try {
      const fetchGetBetHistory = async () => {
        setIsLoading(true);
        const result = await gameAPI.getBetHistory(props.title);
        // console.log(result);

        if (result.status === true) {
          setBetHistory(result.data);
        } else {
          toast.error(result.msg, { theme: "light" });
        }

        setIsLoading(false);
      };

      fetchGetBetHistory();
    } catch (error) {
      return toast.error("Có lỗi xảy ra!", { theme: "light" });
    }
  }, [props.title]);

  return (
    <div>
      {isLoading ? (
        <ReactLoading type="bubbles" color="#ff8a00" height={10} width={100} />
      ) : (
        <div>
          {betHistory.length > 0 ? (
            <table className="table table-bordered rounded table-hover">
              <thead
                className="text-center"
                style={{ backgroundColor: "#e8f0fc" }}
              >
                <th>Thời gian</th>
                <th>Số ghi chú</th>
                <th>Số tiền cược</th>
                <th>Tiền cược hợp lệ</th>
                <th>Thắng thua</th>
              </thead>
              <tbody>
                {betHistory.map((item, i) => {
                  return (
                    <tr key={i} className="text-center">
                      <td>{item.start_time}</td>
                      <td>{item.id_bet}</td>
                      <td>{VND.format(item.bet)}</td>
                      <td>{VND.format(item.turnover)}</td>
                      <td>{VND.format(item.payout)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <>
              <table className="table table-bordered rounded">
                <thead
                  className="text-center"
                  style={{ backgroundColor: "#e8f0fc" }}
                >
                  <th>Thời gian</th>
                  <th>Số ghi chú</th>
                  <th>Số tiền cược</th>
                  <th>Tiền cược hợp lệ</th>
                  <th>Thắng thua</th>
                </thead>
              </table>
              <span className="d-flex align-items-center justify-content-center py-2">
                Không có dữ liệu
              </span>
            </>
          )}

          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )}
    </div>
  );
}

export default BetHistoryByGame;
