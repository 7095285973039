import axiosClient from "./axiosClient";

const transferAPI = {
  getBalance: () => {
    const url = `/api/user/getBalance`;
    return axiosClient.get(url);
  },

  getHistoryTrans: () => {
    const url = `/api/user/tranfer`;
    return axiosClient.get(url);
  },

  postTrans: (data) => {
    const url = `/api/user/tranfer`;
    return axiosClient.post(url, data);
  },
};

export default transferAPI;
