import React, { useEffect, useRef, useState } from 'react';
import { cachchoi } from '../../img/importImg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { chuSo, listTheLoai } from '../../dummy';
import { VND, isValidFormat } from '../../utils';

function LotBetContent(props) {
    const [listTheLoaiNew, setListTheLoaiNew] = useState(listTheLoai);
    const [theloai, setTheLoai] = useState('baolo');
    const [listType, setListType] = useState(listTheLoaiNew[0].listType);
    const [type, setType] = useState('lo2');
    const [kieuNhap, setKieuNhap] = useState('chonso');
    const [isHidden, setIsHidden] = useState(false);
    const [contentCachChoi, setContentCachChoi] = useState(listTheLoaiNew[0].listType[0]);
    const [cacSoCuoc, setCacSoCuoc] = useState();
    const [isHiddenTram, setIsHiddenTram] = useState(false);
    const [isHiddenChuc, setIsHiddenChuc] = useState(false);
    const [isHiddenDonVi, setIsHiddenDonVi] = useState(false);
    const [tram, setTram] = useState([]);
    const [chuc, setChuc] = useState([]);
    const [donVi, setDonVi] = useState([]);
    const [isRender, setIsRender] = useState(true);
    const [daChon, setDaChon] = useState(0);
    const [soNhan, setSoNhan] = useState(1);
    const [thongTinDatCuoc, setThongTinDatCuoc] = useState('');
    const textareaRef = useRef(null);
    const playExplainRef = useRef(null);

    useEffect(() => {
        if (props.tiLe && props.minCuoc) {
            const newListTheLoai = listTheLoaiNew.map((theLoai) => {
                const newTypeList = theLoai.listType.map((type) => {
                    return {
                        ...type,
                        tiLe: props.tiLe[type.value],
                        minCuoc: props.minCuoc[type.value],
                    };
                });

                return {
                    ...theLoai,
                    listType: newTypeList,
                };
            });

            setListTheLoaiNew(newListTheLoai);
            setListType(newListTheLoai[0].listType);
            setContentCachChoi(newListTheLoai[0].listType[0]);
            setIsRender(!isRender);
        }
    }, [props.tiLe]);

    useEffect(() => {
        if (listType) {
            setType(listType[0].value);
        }
    }, [theloai]);

    useEffect(() => {
        listType.forEach((item) => {
            if (item.value === type) {
                setContentCachChoi(item);

                props.parentCallback(item);
            }
        });

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            setIsHiddenTram(true);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(false);
        } else if (type === 'dau') {
            setIsHiddenTram(true);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(true);
        } else if (type === 'duoi') {
            setIsHiddenTram(true);
            setIsHiddenChuc(true);
            setIsHiddenDonVi(false);
        } else if (type === '3cang') {
            setIsHiddenTram(false);
            setIsHiddenChuc(false);
            setIsHiddenDonVi(false);
        }

        setTram([]);
        setChuc([]);
        setDonVi([]);
        setDaChon(0);
        setSoNhan(1);
        setThongTinDatCuoc('');
    }, [type]);

    useEffect(() => {
        if (listType[0].value === 'xien2') {
            setKieuNhap('nhapso');
            setIsHidden(true);
        } else setIsHidden(false);
    }, [listType[0].value]);

    const handleTheLoai = (category) => {
        setTheLoai(category);
        listTheLoaiNew.forEach((item) => {
            if (item.value === category) {
                setListType(item.listType);
            }
        });
    };

    const handleType = (typeNew) => {
        setType(typeNew);
    };

    const handlePlayExplainClick = () => {
        textareaRef.current.focus();
        playExplainRef.current.classList.add('hidden');
    };

    const handleTram = (valueTram) => {
        setIsRender(!isRender);
        const listTram = tram;
        if (listTram.includes(valueTram)) {
            const listTramNew = listTram.filter((item) => item !== valueTram);
            setTram(listTramNew);
        } else {
            listTram.push(valueTram);
            setTram(listTram);
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleChuc = (valueChuc) => {
        setIsRender(!isRender);
        const listChuc = chuc;
        if (listChuc.includes(valueChuc)) {
            const listChucNew = listChuc.filter((item) => item !== valueChuc);
            setChuc(listChucNew);

            if (type === 'dau') {
                const ketQuaChuoi = listChucNew.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        } else {
            listChuc.push(valueChuc);
            setChuc(listChuc);

            if (type === 'dau') {
                const ketQuaChuoi = listChuc.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            if (chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];
                for (let i = 0; i < chuc.length; i++) {
                    for (let j = 0; j < donVi.length; j++) {
                        ketQua.push(chuc[i] + '' + donVi[j]);
                    }
                }
                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleDonVi = (valueDonVi) => {
        setIsRender(!isRender);
        const listDonVi = donVi;
        if (listDonVi.includes(valueDonVi)) {
            const listDonViNew = listDonVi.filter((item) => item !== valueDonVi);
            setDonVi(listDonViNew);
            // console.log(listDonViNew);

            if (type === 'duoi') {
                const ketQuaChuoi = listDonViNew.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        } else {
            listDonVi.push(valueDonVi);
            setDonVi(listDonVi);

            if (type === 'duoi') {
                const ketQuaChuoi = listDonVi.join(',');
                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        let newDaChon = 0;
        if (type === 'lo2' || type === 'de' || type === 'daude') {
            newDaChon = chuc.length * donVi.length;
        } else if (type === 'dau') {
            newDaChon = chuc.length;
        } else if (type === 'duoi') {
            newDaChon = donVi.length;
        } else if (type === '3cang') {
            newDaChon = tram.length * chuc.length * donVi.length;
        }

        setDaChon(newDaChon);

        if (type === 'lo2' || type === 'de' || type === 'daude') {
            if (chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];
                for (let i = 0; i < chuc.length; i++) {
                    for (let j = 0; j < donVi.length; j++) {
                        ketQua.push(chuc[i] + '' + donVi[j]);
                    }
                }
                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }

        if (type === '3cang') {
            if (tram.length > 0 && chuc.length > 0 && donVi.length > 0) {
                let ketQua = [];

                for (let i = 0; i < tram.length; i++) {
                    for (let j = 0; j < chuc.length; j++) {
                        for (let k = 0; k < donVi.length; k++) {
                            ketQua.push(tram[i] + '' + chuc[j] + '' + donVi[k]);
                        }
                    }
                }

                const ketQuaChuoi = ketQua.join(',');

                setThongTinDatCuoc(ketQuaChuoi);
            }
        }
    };

    const handleSoNhanChange = (e) => {
        setSoNhan(Number(e.target.value));
    };

    const handleReset = () => {
        setTram([]);
        setChuc([]);
        setDonVi([]);
        setDaChon(0);
        setSoNhan(1);
        setThongTinDatCuoc('');
        setCacSoCuoc('');
        setIsRender(!isRender);
    };

    const handleClickXoaTram = () => {
        setTram([]);
        setDaChon(0);
    };

    const handleClickXoaChuc = () => {
        setChuc([]);
        setDaChon(0);
    };

    const handleClickXoaDonVi = () => {
        setDonVi([]);
        setDaChon(0);
    };

    const changeTextArea = (e) => {
        setCacSoCuoc(e.target.value);
    };

    const handleXacNhan = () => {
        let isValid = true;
        let daChonNew = 0;

        if (!cacSoCuoc)
            return toast.error(`Bạn chưa chọn số, vui lòng chọn số muốn cược`, {
                theme: 'light',
            });

        const cacSoCuocNew = cacSoCuoc.trim().replace(/\s/g, ',');

        switch (type) {
            case 'lo2':
            case 'de':
            case 'daude':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2,},)*\d{2,}$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            case 'xien2':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'xien3':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'xien4':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{2}),(?!\1)(\d{2}),(?!\1|\2)(\d{2}),(?!\1|\2|\3)(\d{2})$/);
                daChonNew = 1;
                break;
            case 'dau':
            case 'duoi':
                isValid = isValidFormat(cacSoCuocNew, /^(\d,)*\d$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            case '3cang':
                isValid = isValidFormat(cacSoCuocNew, /^(\d{3},)*\d{3}$/);
                daChonNew = cacSoCuocNew.split(',').length;
                break;
            default:
                return;
        }

        if (!isValid) {
            return toast.error(`Vui lòng nhập lại theo đúng định dạng ${contentCachChoi.placeholder} !`, {
                theme: 'light',
            });
        }

        setThongTinDatCuoc(cacSoCuocNew);
        setDaChon(daChonNew);
    };

    const handleDatCuoc = () => {
        if (!thongTinDatCuoc || !soNhan || !contentCachChoi.value) {
            return toast.error(`Nội dung đặt cược không hợp lệ!`, {
                theme: 'light',
            });
        }

        const dataCuoc = {
            // theloai: "1",
            so: thongTinDatCuoc,
            sodiem: soNhan,
            type: contentCachChoi.value,
        };

        props.parentCallbackDatCuoc(dataCuoc);

        handleReset();
    };

    return (
        <>
            <div className="select-game-content">
                <div className="play-type">
                    <div className="cates">
                        <div className="cates_box">
                            <div className="cates_list">
                                {listTheLoaiNew.map((item, i) => {
                                    return (
                                        <div
                                            className={`item ${item.isHot ? 'hot' : ''} ${
                                                theloai === item.value ? 'active' : ''
                                            }`}
                                            key={i}
                                            onClick={() => handleTheLoai(item.value)}
                                        >
                                            {item.title}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="rule">
                            <div className="rule-block">
                                <img
                                    src={cachchoi}
                                    alt=""
                                />
                                <span>Cách chơi</span>
                            </div>
                        </div>
                    </div>

                    <div className="plays-tabs">
                        <div className="plays BL">
                            <div className="list">
                                {listType &&
                                    listType.map((item, i) => {
                                        return (
                                            <div
                                                className={`item ${type === item.value ? 'active' : ''}`}
                                                key={i}
                                                onClick={() => handleType(item.value)}
                                            >
                                                {item.title}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="game-tabs">
                            <div className="item-warp">
                                {!isHidden && (
                                    <div
                                        className={`item ${kieuNhap === 'chonso' ? 'active' : ''}`}
                                        onClick={() => setKieuNhap('chonso')}
                                    >
                                        <span>Chọn số</span>
                                    </div>
                                )}
                                <div
                                    className={`item ${kieuNhap === 'nhapso' ? 'active' : ''}`}
                                    onClick={() => setKieuNhap('nhapso')}
                                >
                                    <span>Nhập số</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plays">
                    {kieuNhap === 'chonso' ? (
                        <div className="play-nums">
                            {!isHiddenTram && (
                                <div className="play-item play-num">
                                    <div className="num-bit-contain">
                                        <div className="play-tag num-bit num-bit--vi">
                                            <span>Trăm</span>
                                            <div className="tag-bg"></div>
                                        </div>
                                        <div className="balls_wrap">
                                            <div className="balls">
                                                {chuSo.map((item, i) => {
                                                    return (
                                                        <div
                                                            className={`num ${
                                                                tram.includes(item.value) ? 'active' : ''
                                                            }`}
                                                            key={i}
                                                            onClick={() => handleTram(item.value)}
                                                        >
                                                            <div>{item.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {/* <div className="omission"></div> */}
                                        </div>
                                        <div
                                            className="opts"
                                            onClick={handleClickXoaTram}
                                        >
                                            <div className="opt-item">Xoá</div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!isHiddenChuc && (
                                <div className="play-item play-num">
                                    <div className="num-bit-contain">
                                        <div className="play-tag num-bit num-bit--vi">
                                            <span>Chục</span>
                                            <div className="tag-bg"></div>
                                        </div>
                                        <div className="balls_wrap">
                                            <div className="balls">
                                                {chuSo.map((item, i) => {
                                                    return (
                                                        <div
                                                            className={`num ${
                                                                chuc.includes(item.value) ? 'active' : ''
                                                            }`}
                                                            key={i}
                                                            onClick={() => handleChuc(item.value)}
                                                        >
                                                            <div>{item.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {/* <div className="omission"></div> */}
                                        </div>
                                        <div
                                            className="opts"
                                            onClick={handleClickXoaChuc}
                                        >
                                            <div className="opt-item">Xoá</div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!isHiddenDonVi && (
                                <div className="play-item play-num">
                                    <div className="num-bit-contain">
                                        <div className="play-tag num-bit num-bit--vi">
                                            <span>Đơn vị</span>
                                            <div className="tag-bg"></div>
                                        </div>
                                        <div className="balls_wrap">
                                            <div className="balls">
                                                {chuSo.map((item, i) => {
                                                    return (
                                                        <div
                                                            className={`num ${
                                                                donVi.includes(item.value) ? 'active' : ''
                                                            }`}
                                                            key={i}
                                                            onClick={() => handleDonVi(item.value)}
                                                        >
                                                            <div>{item.title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {/* <div className="omission"></div> */}
                                        </div>
                                        <div
                                            className="opts"
                                            onClick={handleClickXoaDonVi}
                                        >
                                            <div className="opt-item">Xoá</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="play-textarea">
                            <textarea
                                className="textarea-warp"
                                cols={30}
                                rows={9}
                                onChange={(e) => changeTextArea(e)}
                                ref={textareaRef}
                                value={cacSoCuoc}
                            ></textarea>

                            {!cacSoCuoc && (
                                <div
                                    className="play-explain"
                                    onClick={handlePlayExplainClick}
                                    ref={playExplainRef}
                                >
                                    Cách chơi:
                                    <br />
                                    <span>
                                        Giữa mỗi số cược cần phân cách nhau bởi dấu , hoặc cách nhau bởi khoảng trống
                                    </span>
                                    <br />
                                    <span>Ví dụ: {contentCachChoi && contentCachChoi.placeholder}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="bet-container">
                <div className="bet-input">
                    <div className="bet-info">
                        <div className="input-odds">
                            Tỉ lệ cược : <span>1</span> : <span>{contentCachChoi?.tiLe / 1000}</span>
                        </div>
                        <div className="ml-4">
                            Đã chọn : <span>{daChon}</span>
                        </div>
                        <div className="ml-4">
                            Số tiền : <span>{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</span>
                        </div>
                    </div>
                    <div className="bet-btns">
                        <span>
                            <input
                                className="basic-slide"
                                id="so-nhan"
                                type="number"
                                value={soNhan}
                                onChange={(e) => handleSoNhanChange(e)}
                            />
                            <label htmlFor="name">Số nhân</label>
                        </span>

                        {kieuNhap === 'nhapso' && (
                            <div
                                className="bet-btn btn-add"
                                onClick={handleXacNhan}
                            >
                                Xác nhận
                            </div>
                        )}

                        <div
                            className="reset"
                            onClick={handleReset}
                        >
                            Cài đặt lại
                        </div>
                    </div>
                </div>
                <div className="bets">
                    <div className="bets-box">
                        <div className="table-title">Nội dung cược</div>
                        <div className="item table-name row">
                            <div className="col-2">Cách chơi</div>
                            <div className="col-4">Thông tin đặt cược</div>
                            <div className="col-1">Số đơn</div>
                            <div className="col-1">Số nhân</div>
                            <div className="col-2">Số tiền cược</div>
                            <div className="col-2">Tiền thắng/1 lần</div>
                            {/* <div className="col-2">Thao tác</div> */}
                        </div>
                        <div className="b-content">
                            {daChon === 0 ? (
                                <div className="no-data">
                                    <p>Không có dữ liệu</p>
                                </div>
                            ) : (
                                <div className="item table-name row content-table">
                                    <div className="col-2">{contentCachChoi && contentCachChoi.title}</div>
                                    <div className="col-4">{daChon !== 0 && thongTinDatCuoc}</div>
                                    <div className="col-1">1</div>
                                    <div className="col-1">{soNhan}</div>
                                    <div className="col-2">{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</div>
                                    <div className="col-2">{VND.format(contentCachChoi?.tiLe * soNhan)}</div>
                                    {/* <div className="col-2">Chờ xác nhận</div> */}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bet-btns d-flex align-items-center justify-content-between mx-5 mt-4">
                        <div className="total">
                            Tổng số tiền : <span>{VND.format(daChon * contentCachChoi.minCuoc * soNhan)}</span>
                        </div>
                        <div className="d-flex">
                            <div
                                className="bet-btn btn-detel big mr-2"
                                onClick={handleReset}
                            >
                                Xoá hết
                            </div>
                            {daChon != 0 && (
                                <div
                                    className="bet-btn btn-submit big"
                                    onClick={handleDatCuoc}
                                >
                                    Đặt cược
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default LotBetContent;
