import React from "react";
import { titleImage2, imgLeft, download, qr } from "../../img/importImg";

function HomeAbout() {
  return (
    <div className="home-about">
      <div className="container">
        <div className="head-img">
          <img src={titleImage2} alt="" />
        </div>
        <div className="wrap-about">
          <div className="image">
            <img src={imgLeft} alt="" />
          </div>
          <div className="ct">
            <h3 className="title">Cá cược trực tuyến</h3>
            <div className="desc">
              Cung cấp hơn 100 máy đánh bạc / baccarat và nhiều trò chơi cá cược
              đa dạng như: xổ số, cá cược thể thao, casino trực tuyến... Cùng
              nhiều sản phẩm trải nghiệm thực tế khác. Chúng tôi luôn nỗ lực hết
              mình để đem đến cho khách hàng các nội dung giải trí hàng đầu, đa
              dạng, phong phú và đặc sắc nhất để cùng xây dựng cộng đồng game
              giải trí trực tuyến ổn định và tốt nhất khu vực Đông Nam Á.
            </div>
            {/* <div className="app">
              <div className="left">
                <img src={download} alt="" />
              </div>
              <div className="qr-code">
                <a href="/">
                  <img src={qr} alt="" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAbout;
